import { Images } from '@/hooks/images';
import { useIntl } from '@/hooks/tvClient';
import React from 'react';

interface MiddleTopStatusProps {
  deviceStatus?: any;
  tvUid: string;
}

const MiddleTopStatus: React.FC<MiddleTopStatusProps> = ({deviceStatus, tvUid}) => {
  const intl = useIntl(tvUid);
  let text: string = '';
  let icon: string = '';
  if (deviceStatus && deviceStatus.online === false) {
    text = intl.formatMessage({id: 'text.deviceOffline'});
    icon = Images.lixian;
  } else if (deviceStatus && deviceStatus.powerOn === false) {
    text = intl.formatMessage({id: 'text.deviceStandby'});
    icon = Images.daiji;
  } else {
    text = intl.formatMessage({id: 'text.disinfecting'});
    icon = Images.titleClean;
  }
  return (<>
    <div style={{
      fontSize: '38px',
    }}>
      <span style={{verticalAlign: 'middle'}}>
        {text} 
      </span>
      <img src={icon} width={70} height={70} style={{marginLeft: 10,}} />
    </div>
  </>);
}

export default MiddleTopStatus;