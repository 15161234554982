import React from 'react';
import FlipNumbers from "react-flip-numbers";

interface BarStatItemProps {
  numberValue?: number;
  precision?: number;
  unit: string;
  label: string;
}

const BarStatItem: React.FC<BarStatItemProps> = (props) => {
  return (<div style={{ width: 300, display: 'inline-block',}}>
    <div style={{
      display: 'inline-block',
      height: 50,
      width: 7,
      borderRadius: 20,
      backgroundColor: 'white',
      marginRight: 15,
    }}></div>
    <div style={{
      display: 'inline-block',
    }} className="bar-flip-numbers">
      <FlipNumbers
        height={36}
        width={20}
        color="white"
        play
        duration={2}
        nonNumberStyle={{
          height: 20,
          width: 10,
          fontSize: 'x-large',
          fontWeight: '800',
        }}
        numbers={props.numberValue?.toFixed(props.precision === undefined ? 2 : props.precision) ?? ''}
      />
      <div style={{
        fontSize: 18,
        marginTop: 5,
      }}>{props.label}（{props.unit}）</div>
    </div>
  </div>);
}

export default BarStatItem;