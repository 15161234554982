export default {
  'config.tv-client.addNewTvClient': 'Add new TV client',
  'config.tv-client.modifyTvClient': 'Modify TV client',
  'config.tv-client.deleteTvClient': 'Delete TV client',
  'config.tv-client.deleteTvClient.confirm': 'Are you sure to delete the TV client “{tvClientName}”?',
  'config.tv-client.tvClientUid': 'TV client UID',
  'config.tv-client.tvClientUid.require': 'Please input the TV client UID!',
  'config.tv-client.online': 'Online',
  'config.tv-client.tvClientName': 'Name',
  'config.tv-client.tvClientName.require': 'Please input the TV client name!',
  'config.tv-client.displayType': 'Display type',
  'config.tv-client.displayType.Device': 'Device',
  'config.tv-client.displayType.Node': 'Space',
  'config.tv-client.displayItem': 'Display item',
  'config.tv-client.displayStyle': 'Display style',
  'config.tv-client.displayStyle.require': 'Please input the display style!',
  'config.tv-client.displayStyle.FullScreen': 'Full screen',
  'config.tv-client.displayStyle.Top': 'Top of screen',
  'config.tv-client.displayStyle.Bottom': 'Bottom of screen',
  'config.ruleset.deviceRuleset': 'Rule set',
  'config.ruleset.addNewRule': 'Add new rule',
  'config.ruleset.deleteRule': 'Delete',
  'config.ruleset.ruleOrder': 'Execution order',
  'config.ruleset.x400Condition': 'X400 condition',
  'config.ruleset.x1000Condition': 'X1000 condition',
  'config.ruleset.x400Command': 'X400 command',
  'config.ruleset.x1000Command': 'X1000 command',

  'operation.tab.summary': 'Statistical summary',
  'operation.tab.devices': 'Device list',

  'device.function.powerOn': 'Power on/off',
  'device.function.modeAndShift': 'Fan speed',
  'device.function.lockOn': 'Child lock',
  'device.function.uvOn': 'UV',
  'device.function.anionOn': 'Plasma',
  'device.function.ledOff': 'Screen off',
  'device.function.beepOn': 'Beep',
  'device.function.colorLightMode': 'Ambient Lamp',

  'device.function.modeAndShift.1': 'Sleep Mode',
  'device.function.modeAndShift.2': 'Auto Mode',
  'device.function.modeAndShift.3': 'Shift 1',
  'device.function.modeAndShift.4': 'Shift 2',
  'device.function.modeAndShift.5': 'Shift 3',
  'device.function.modeAndShift.6': 'Shift 4',
  'device.function.modeAndShift.7': 'Shift 5',
  'device.function.modeAndShift.8': 'Shift 6',

  'device.mode.offline': 'Offline',
  'device.mode.powerOff': 'Power off',
  'device.mode.noPowerSupply': 'Power supply disconnected',
  'device.mode.Mode_0_Shift_0': 'Manual shift 1',
  'device.mode.Mode_0_Shift_1': 'Manual shift 2',
  'device.mode.Mode_0_Shift_2': 'Manual shift 3',
  'device.mode.Mode_0_Shift_3': 'Manual shift 4',
  'device.mode.Mode_0_Shift_4': 'Manual shift 5',
  'device.mode.Mode_0_Shift_5': 'Manual shift 6',
  'device.mode.Mode_1_Shift_0': 'Auto shift 1',
  'device.mode.Mode_1_Shift_1': 'Auto shift 2',
  'device.mode.Mode_1_Shift_2': 'Auto shift 3',
  'device.mode.Mode_1_Shift_3': 'Auto shift 4',
  'device.mode.Mode_1_Shift_4': 'Auto shift 5',
  'device.mode.Mode_1_Shift_5': 'Auto shift 6',
  'device.mode.Mode_2_Shift_0': 'Sleep shift 1',

  'device.alert.offline': 'Offline',
  'device.alert.noPowerSupply': 'Power supply disconnected',
  'device.alert.filterUsedUp': 'Filter element alarm',
  'device.alert.doorOpen': 'Cartridge door is open',
  'device.alert.filterElementPackageNotRemoved': 'Filter element package is not removed',

  'device.command.status.offline': 'Device offline',
  'device.command.status.jam': 'Command is jammed',
  'device.command.status.sent': 'Command has been sent',
  'device.command.status.received': 'Command has been received',
  'device.command.status.timeout': 'Command timeout',
  'device.command.status.set': 'Command has been set',

  'device.function.restoreAfterHours': 'hours before restore scene/rule',
  'device.function.concurrency': 'Concurrency',
  'device.function.ruleSet': 'Set up intelligent rules',
  'device.scene.apply_device_scene': 'Enable scene or not',
  'device.scene.device_scene_id': 'Select intelligent scene',

  'isoCountryCode.CHN': "People's Republic of China",
  'isoCountryCode.MYS': 'Malaysia',

  'appclient.error.needLocationPermission': 'Location permission is required, please try again',

  'app.copyright': '2020-2070 All Rights Reserved 720 (Beijing) Healthy Technology Co., Ltd. ',
  'device.list.deviceSN': 'Device SN',
  'device.list.spacePath': 'space attribution',
  'device.list.category': 'Category',
  'device.list.deviceType': 'Device type',
  'device.list.status': 'Status',
  'device.list.alert': 'Alarm',
  'device.list.filterLife': 'Residual filter life',
  'device.list.scene': 'Scene',
  'device.list.scenePause': 'Scene pause',
  'device.list.deviceCount': 'Device count:',
  'device.list.batchSetScene': 'Set scenes in batches',
  'device.list.batchCommand': 'Immediate commands in batches',
  'filterLife.stats.now': 'Replace now',
  'filterLife.stats.days30': 'Replace within 30 days',
  'filterLife.stats.days60': 'Replace within 60 days',
  'filterLife.stats.noNeed': 'No need to replace',
  'scene.stats.notSet': 'Null',
  'scene.stats.apply': 'Enable',
  'scene.stats.pause': 'Pause',
  'scene.stats.stop': 'Disable',
  'device.locations': 'Geographic information({count} in total)',
  'device.types': 'Device distribution',
  'device.alerts': 'Device alarm',
  'device.stats.status': 'Device status',
  'device.stats.X1000FilterLife': 'X1000 filter replacement reminding',
  'device.stats.X400FilterLife': 'X400 filter replacement reminding',
  'device.stats.scene': 'Intelligent scene distribution',
  'device.list.onlyAllowSameType': 'Please select the same type of device',
  'device.list.cannotAcrossOrg': 'Unable to set scenes in batch across organizations',
  'device.list.noChange': 'Remained',
  'device.list.on': 'On',
  'device.list.off': 'Off',
  'device.list.unknown': 'Unknown',
  'colorLightMode.0': 'Off',
  'colorLightMode.1': 'Auto',
  'colorLightMode.2': 'Others',
  'colorLightMode.3': 'Blue',
  'colorLightMode.4': 'Purple',
  'colorLightMode.5': 'Green',
  'colorLightMode.6': 'Yellow',
  'colorLightMode.7': 'Red',
  'pm25.gauge.fine': 'Excellent',
  'pm25.gauge.good': 'Good',
  'pm25.gauge.poor': 'Poor',
  'pm25.indoor': 'Indoor PM2.5',
  'pm25.outdoor': 'Outdoor PM2.5',
  'temperature.indoor': 'Indoor temperature',
  'temperature.gauge.cold': 'Cold',
  'temperature.gauge.comfort': 'Comfortable',
  'temperature.gauge.hot': 'Hot',
  'humidity.indoor': 'Indoor humidity',
  'humidity.gauge.dry': 'Dry',
  'humidity.gauge.littleDry': 'A little dry',
  'humidity.gauge.comfort': 'Comfortable',
  'unit.hour': 'Hour',
  'label.workTime': 'Cumulative work',
  'unit.purified': 'm³',
  'label.purified': 'Cumulative Vol.',
  'label.purifiedAvg': 'Average Vol.',
  'unit.purifiedAvg': 'm³/EA·hr',
  'label.powerCost': 'Cumulative Power',
  'unit.powerCost': 'kWh',
  'label.powerCostAvg': 'Average unit power consumption',
  'unit.powerCostAvg': 'kWh/EA·hr',
  'period.month': 'Month',
  'period.week': 'Week',
  'period.day': 'Day',
  'chart.purify': 'Purification data',
  'chart.powerCost': 'Energy consumption data',
  'dlg.noChange': 'Remained',
  'dlg.noNeedToChange': 'No settings to be changed',
  'dlg.noNeedToSendCommand': 'No commands to be sent',
  'dlg.notSetCommand': 'No commands are set',
  'dlg.restoreAfterHours.required': 'Please input "Restore smart scenes/rules after hours"',
  'dlg.concurrency.required': 'Please input concurrency',
  'dlg.prev': 'Back',
  'dlg.next': 'Next',
  'dlg.cancel': 'Cancle',
  'dlg.finish': 'Finish',
  'dlg.table.seq': 'Sequence',
  'dlg.table.sn': 'Device SN',
  'dlg.table.commandStatus': 'Command sending status',
  'dlg.table.count': 'Total : {count}',
  'dlgDeviceScene.title': 'Set the intelligent scene of the device',
  'dlgDeviceScene.step1': 'List of devices selected',
  'dlgDeviceScene.step2': 'Scene configuration',
  'dlgDeviceScene.step3': 'Processing progress',
  'dlgDeviceCommand.title': 'Send an immediate command to the device',
  'dlgDeviceCommand.step1': 'List of devices selected',
  'dlgDeviceCommand.step2': 'Command configuration',
  'dlgDeviceCommand.step3': 'Processing progress',
  'device.location': 'Device location',
  'device.setScene': 'Device scene',
  'device.sendCommand': 'Send command',
  'device.card.status': 'Device status',
  'device.card.alerts': 'Device alarm',
  'device.card.mode': 'Mode',
  'device.card.mode.manual': 'Manual',
  'device.card.mode.auto': 'Auto',
  'device.card.mode.sleep': 'Sleep',
  'device.card.speed': 'Fan speed',
  'device.card.shift': '{shift} shift',
  'device.card.basicInfo': 'Device basic information',
  'device.card.refresh': 'Refresh',
  'device.card.firmwareVersion': 'Firmware version',
  'device.card.remain': 'Remaining',
  'device.card.currentRuleset': 'Current rule set',
  'device.card.noRuleset': 'None',
  'ruleset.empty': 'Empty',
  'ruleset.custom': 'Custom',
  'ruleset.moveUp': 'Move up',
  'ruleset.humanActivity': 'Human activities nearby',
  'ruleset.lightNearby': 'The nearby environment is bright',
  'ruleset.powerOn': 'Power on',
  'ruleset.powerOff': 'Power off',
  'ruleset.modeSetTo': 'Set to {mode}',
  'ruleset.colorLightModeSetTo': 'Ambient Lamp set to {mode}',
  'app.yes': 'Yes',
  'app.no': 'No',
  'scene.apply_on_workday': 'Workdays',
  'scene.apply_on_holiday': 'Nonworkdays',
  'scene.apply_on_monday': 'Monday',
  'scene.apply_on_tuesday': 'Tuesday',
  'scene.apply_on_wednesday': 'Wednesday',
  'scene.apply_on_thursday': 'Thursday',
  'scene.apply_on_friday': 'Friday',
  'scene.apply_on_saturday': 'Saturday',
  'scene.apply_on_sunday': 'Sunday',
  'scene.by_workday_dayoff': 'by workdays/nonworkdays',
  'scene.by_weekday': 'by one workday',
  'scene.date_condition_required': 'At least one date condition needs to be selected',
  'scene.command_ruleset_required': 'No command or rule set is set',
  'scene.noChangeRuleset': 'The rule set remains unchanged',
  'scene.emptyRuleset': 'Empty rule set',
  'scene.customRuleset': 'Custom rule set',
  'xuanwuApi.error.cannotDeleteNotEmptySpace': 'Cannot delete a non-empty space node',
  'xuanwuApi.error.cannotDeleteNotEmptyOrg': 'Cannot delete a non-empty organization node',
  'opclient.xuanwuOperation': 'Xuanwu OpTool',
  'opclient.logout': 'Logout',
  'opclient.installPuriX': 'Install X series',
  'opclient.testPuriX': 'Test X series',
  'opclient.setupTvClient': 'Setup the TV Client',
  'opclient.accessDeny': 'No enough permissions',
  'opclient.notPuriX': 'Not valid SN for X series devices',
  'opclient.setupWifiFinished': 'Binding and Wi-Fi configuration completed',
  'opclient.setupWifiCancelled': 'Binding completed but Wi-Fi configuration cancelled',
  'opclient.clickToSelectSpace': 'Please click to select a space',
  'opclient.bindSpace': 'Bind space',
  'opclient.deviceSN': 'Device SN',
  'opclient.clickToBind': 'Please click to scan QR code',
  'opclient.bindFinish': 'Binding completed',
  'opclient.bindDevice': 'Bind device',
  'opclient.andSetupWifi': ' and configure Wi-Fi',
  'opclient.notQrCodeForTvSetup': 'Not valid QR code',
  'opclient.onlySpaceNodeAllowed': 'Only space node can be selected!',
  'opclient.cannotBindToSpaceNodeWithChildren': 'Cannot bind to a node with sub-spaces',
  'opclient.selectSpace': 'Select space',
  'opclient.sendCommand': 'Send {deviceTypeShort} command',
  'opclient.clickToScan': 'Please click to scan QR code',
  'opclient.powerSupply': 'Power supply available',
  'opclient.lastManualCmd': 'Key-press',
  'opclient.lastManualTime': 'Key-press time',
  'opclient.onlineCause': 'Online cause',
  'opclient.macAddress': 'MAC address',
  'opclient.ipAddress': 'IP address',
  'opclient.oidVersion': 'oid address',
  'opclient.totalAbsorbed': 'Cumulative absorption',
  'opclient.debugInfo': 'Debugging information',
  'opclient.filterElementPackage': 'Filter element package',
  'opclient.notRemoved': 'Not removed',
  'opclient.removed': 'Removed',
  'opclient.genuineFilterElement': 'Authorized filter element',
  'opclient.notDetected': 'not detected',
  'opclient.detected': 'detected',
  'opclient.displayContent.required': 'Please select display item!',
  'opclient.selectDisplayContent': 'Select display item',
  'opclient.orgNode.required': 'Please select organization!',
  'opclient.onlyAllowOrgNodeWithoutChildren': 'Only end organizations can be selected',
  'opclient.selectOrgNode': 'Select organization',
  'opclient.displaySettings': 'TV client settings',
  'opclient.orgNode': 'Organization',
  'opclient.displayName': 'TV client name',
  'opclient.selectOrgNodeFirst': 'Please select organization first',
  'opclient.success': 'Operation succeed',
  'opclient.deleted': 'Deleted',


  'config.tv-client.displayStyle.DebugFullScreen': 'Debug Full screen',
  'config.tv-client.displayStyle.DebugTop': 'Debug Top of screen',
  'config.tv-client.displayStyle.DebugBottom': 'Debug bottom of screen',
  'config.tv-client.displayLocale': 'Locale',
  'config.tv-client.displayLocale.require': 'Please select locale!',
  'opclient.selectDisplayLocale': 'Select locale',

  'locale.zh-CN': 'Simplified Chinese',
  'locale.en-US': 'English',
  'locale.zh-TW': 'Traditional Chinese',

  'unit.pm25': 'μg / m³',
  'text.indoor': 'Indoor',
  'text.outdoor': 'Outdoor',
  'level.pm25.优': 'Excellent',
  'level.pm25.良': 'Good',
  'level.pm25.轻度污染': 'Moderate',
  'level.pm25.中度污染': 'Poor',
  'level.pm25.重度污染': 'Unhealthy',
  'level.pm25.严重污染': 'Hazardous',
  'format.date.moment': 'YYYY/MM/DD HH:mm',
  'text.disinfecting': 'Disinfecting',
  'text.deviceStandby': 'Device standby',
  'text.deviceOffline': 'Device offline',
  'text.filterRemain': 'Filter',
  'text.powerOff': 'Power off',
  'text.manual': 'Manual',
  'text.auto': 'Auto',
  'text.sleep': 'Sleep',
  'text.shift': 'Shift{shift}',
  'text.disinfector': 'Disinfector',
  'text.deployed': 'deployed',
  'text.deviceUnit': '',
  'level.temperature.寒冷': 'Too cold',
  'level.temperature.冷': 'Cold',
  'level.temperature.舒适': 'Comfortable',
  'level.temperature.热': 'Warm',
  'level.temperature.酷热': 'Too warm',
  'level.humidity.干燥': 'Dry',
  'level.humidity.略干': 'A bit dry',
  'level.humidity.舒适': 'Comfortable',
  'level.humidity.潮湿': 'Humid',
};
