import React from 'react';
import moment from "moment";
import { useIntl } from '@/hooks/tvClient';

interface SpaceBoxProps {
  display?: any;
  tvUid: string;
}

const SpaceBox: React.FC<SpaceBoxProps> = ({display, tvUid}) => {
  const intl = useIntl(tvUid);
  return (<>
    <div style={{
      position: 'fixed',
      right: 30,
      top: 10,
      textAlign: 'right',
    }}>
      <div style={{
        fontSize: '20px',
      }}>
        {moment().format(intl.formatMessage({id: 'format.date.moment'}))}
      </div>
      <div style={{
        fontSize: '40px',
      }}>
        {display?.displayPath ? display.displayPath[0] : ''}
      </div>
    </div>
  </>);
}

export default SpaceBox;