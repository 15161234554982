import enUS from '@/locales/en-US';
import zhCN from '@/locales/zh-CN';
import zhTW from '@/locales/zh-TW';
import axios from 'axios';
import { useEffect } from 'react';
import { useQuery, queryCache } from 'react-query';
import useBasePath from "./useBasePath";

const basePath = useBasePath();

function useTvClientDisplay(tvUid: string) {
  useEffect(() => {
    queryCache.invalidateQueries(['tvClientDisplay']);
    return () => {
      queryCache.removeQueries(['tvClientDisplay']);
    }
  }, []);

  return useQuery(['tvClientDisplay'], async () => {
    const {data: display} = await axios.get(`${basePath}/tv-clients/${tvUid}/display`);
    return display;
  }, {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

function useTvClientDisplayNodeSummary(tvUid: string) {
  useEffect(() => {
    queryCache.invalidateQueries(['tvClientDisplayNodeSummary']);
    return () => {
      queryCache.removeQueries(['tvClientDisplayNodeSummary']);
    }
  }, []);

  return useQuery(['tvClientDisplayNodeSummary'], async () => {
    const {data: nodeSummary} = await axios.get(`${basePath}/tv-clients/${tvUid}/display-node-summary`);
    return nodeSummary;
  }, {
    retry: false,
    staleTime: Infinity,
    refetchInterval: 20*1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
}

function useTvClientDisplayDeviceLocations(tvUid: string) {
  useEffect(() => {
    queryCache.invalidateQueries(['tvClientDisplayDeviceLocations']);
    return () => {
      queryCache.removeQueries(['tvClientDisplayDeviceLocations']);
    }
  }, []);

  return useQuery(['tvClientDisplayDeviceLocations'], async () => {
    const {data: nodeDevicesLocations} = await axios.get(`${basePath}/tv-clients/${tvUid}/display-device-locations`);
    return nodeDevicesLocations;
  }, {
    retry: false,
    staleTime: Infinity,
    refetchInterval: 5*60*1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
}

function useTvClientDisplayDeviceStats(tvUid: string) {
  useEffect(() => {
    queryCache.invalidateQueries(['tvClientDisplayDeviceStats']);
    return () => {
      queryCache.removeQueries(['tvClientDisplayDeviceStats']);
    }
  }, []);

  const intl = useIntl(tvUid);

  return useQuery(['tvClientDisplayDeviceStats'], async () => {
    const {data: deviceStats} = await axios.get(`${basePath}/tv-clients/${tvUid}/display-device-stats`);
    const deviceModeAndShiftData = [
      {
        status: intl.formatMessage({id: 'device.mode.offline'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['offline'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.mode.powerOff'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['powerOff'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.function.modeAndShift.2'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['mode_1'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.function.modeAndShift.1'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['mode_2'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.function.modeAndShift.3'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['shift_0'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.function.modeAndShift.4'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['shift_1'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.function.modeAndShift.5'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['shift_2'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.function.modeAndShift.6'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['shift_3'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.function.modeAndShift.7'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['shift_4'] || 0 : 0,
      },
      {
        status: intl.formatMessage({id: 'device.function.modeAndShift.8'}),
        count: deviceStats?.statsModeAndShift ? deviceStats?.statsModeAndShift['shift_5'] || 0 : 0,
      },
    ];
    return {...deviceStats, deviceModeAndShiftData};
  }, {
    retry: false,
    staleTime: Infinity,
    refetchInterval: 5*1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
}

function getPm25Level(pm25: number) {
  for (var i = 0; i < PM25Level.length; i++) {
    if (pm25 < Number(PM25Level[i].Value)) {
      return PM25Level[i];
    }
  }
  return undefined;
}

const PM25Level = [
  {
    "Value":"35",
    "Level": "优",
    "LevelEng": "Good",
    "Color": "#58dd83",
    "Comment": "需要开窗通风，改善环境。",
    "Activity":[]
  },
  {
    "Value":"75",
    "Level": "良",
    "LevelEng": "Moderate",
    "Color": "#a8e22c",
    "Comment": "需要开窗通风，改善环境。",
    "Activity":[]
  },
  {
    "Value":"115",
    "Level": "轻度污染",
    "LevelEng": "Slightly Unhealthy",
    "Color": "#fdd379",
    "Comment": "有呼吸系统疾病的人原有症状轻度加剧，健康人群出现呼吸系统刺激症状。",
    "Activity":["净化空气","戴口罩","在家看书"]
  },
  {
    "Value":"150",
    "Level": "中度污染",
    "LevelEng": "Unhealthy",
    "Color": "#fe989a",
    "Comment": "有呼吸系统疾病的人或心血管疾病患者原有症状加剧，健康人群心脏、呼吸系统或受影响，请做好防护。",
    "Activity":["净化空气","戴口罩"]
  },
  {
    "Value":"250",
    "Level": "重度污染",
    "LevelEng": "Very Unhealthy",
    "Color": "#f15b5d",
    "Comment": "有呼吸系统疾病的人或心血管疾病患者原有心肺症状显著加剧，运动耐受力降低，健康人群出现症状，请避免外出。",
    "Activity":["净化空气","戴口罩"]
  },
  {
    "Value":"9999",
    "Level": "严重污染",
    "LevelEng": "Hazardous",
    "Color": "#b70364",
    "Comment": "有呼吸系统疾病的人或心血管疾病患者会有明显咳喘、胸闷、心慌、气短、呼吸困难等症状，请必要时及时就医。",
    "Activity":["净化空气","戴口罩"]
  }
];

function useTvClientDisplayNodeWeather(tvUid: string) {
  useEffect(() => {
    queryCache.invalidateQueries(['tvClientDisplayNodeWeather']);
    return () => {
      queryCache.removeQueries(['tvClientDisplayNodeWeather']);
    }
  }, []);

  return useQuery(['tvClientDisplayNodeWeather'], async () => {
    const {data} = await axios.get(`${basePath}/tv-clients/${tvUid}/display-node-weather`);
    return data;
  }, {
    retry: false,
    staleTime: Infinity,
    refetchInterval: 10*60*1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
}

function useTvClientDisplayDeviceStatus(tvUid: string) {
  useEffect(() => {
    queryCache.invalidateQueries(['tvClientDisplayDeviceStatus']);
    return () => {
      queryCache.removeQueries(['tvClientDisplayDeviceStatus']);
    }
  }, []);

  return useQuery(['tvClientDisplayDeviceStatus'], async () => {
    const {data} = await axios.get(`${basePath}/tv-clients/${tvUid}/display-device-status`);
    return data;
  }, {
    retry: false,
    staleTime: Infinity,
    refetchInterval: 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
}

function useTvClientDisplayDeviceWeather(tvUid: string) {
  useEffect(() => {
    queryCache.invalidateQueries(['tvClientDisplayDeviceWeather']);
    return () => {
      queryCache.removeQueries(['tvClientDisplayDeviceWeather']);
    }
  }, []);

  return useQuery(['tvClientDisplayDeviceWeather'], async () => {
    const {data} = await axios.get(`${basePath}/tv-clients/${tvUid}/display-device-weather`);
    return data;
  }, {
    retry: false,
    staleTime: Infinity,
    refetchInterval: 10*60*1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
}

function isPortraitScreen() {
  return (screen.height > screen.width);
}

function getTemperatureLevel(temperature: number) {
  for (var i = 0; i < TemperatureLevel.length; i++) {
    if (temperature < Number(TemperatureLevel[i].Value)) {
      return TemperatureLevel[i];
    }
  }
  return undefined;
}

const TemperatureLevel = [
  {
    "Value":"4",
   "Level":"寒冷",
   "LevelEng":"Too Cold",
   "Color":"#8cd3fc",
   "tucao": "室温很冷",
   "Comment":"请及时添加衣服，减少或避免外出，防上感冒，呼吸道疾病的急性发作或加重。",
    "Activity":["吃火锅","泡温泉","喝点小酒"]
  },
  {
    "Value":"18",
    "Level":"冷",
    "LevelEng":"Cold",
    "Color":"#bae4fc",
    "tucao": "室温很冷",
    "Comment":"请及时添加衣服，减少或避免外出，防上感冒，呼吸道疾病的急性发作或加重。",
    "Activity":["享受清凉","吃火锅"]
  },
  {
    "Value":"28",
    "Level":"舒适",
    "LevelEng":"Comfortable",
    "Color":"#58dd83",
    "tucao": "",
    "Comment":"请及时添加衣服，减少或避免外出，防上感冒，呼吸道疾病的急性发作或加重。",
    "Activity":["臭美","喝红酒","享受清凉"]
  },
  {
    "Value":"37",
    "Level":"热",
    "LevelEng":"Warm",
    "Color":"#fdd379",
    "tucao": "室温很热",
    "Comment":"",
    "Activity":["开空调","喝啤酒","撸串","吃西瓜","睡凉席","喝凉茶","擦风油精"]
  },
  {
    "Value":"99",
    "Level":"酷热",
    "LevelEng":"Too Warm",
    "Color":"#f15b5d",
    "tucao": "室温太热了",
    "Comment":"防暑降温，要记得防紫外线，避免在外停留较长时间。",
    "Activity":["开空调","冲凉","吃西瓜","睡凉席","喝凉茶","擦风油精"]
  }
];

function getHumidityLevel(humidity: number) {
  for (var i = 0; i < HumidityLevel.length; i++) {
    if (humidity < Number(HumidityLevel[i].Value)) {
      return HumidityLevel[i];
    }
  }
  return undefined;
}

const HumidityLevel = [
  {
    "Value":"20",
    "Level":"干燥",
    "LevelEng":"Dry",
    "Color":"#fdd379",
    "tucao": "空气太干了",
    "Comment":"室内需要增加加湿器了，改善干燥情况。",
    "Activity":["喝水","敷面膜","吃水果"]
  },
  {
    "Value":"40",
    "Level":"略干",
    "LevelEng":"A Bit Dry",
    "Color":"#a8e22c",
    "tucao": "",
    "Comment":"室内需要增加加湿器了，改善干燥情况。",
    "Activity":["喝水","敷面膜","吃水果"]
  },
  {
    "Value":"65",
    "Level":"舒适",
    "LevelEng":"Comfortable",
    "Color":"#58dd83",
    "tucao": "",
    "Comment":"该湿度有益身体健康，可放心。",
    "Activity":[]
  },
  {
    "Value":"101",
    "Level":"潮湿",
    "LevelEng":"Humid",
    "Color":"#bae4fc",
    "tucao": "空气有点潮",
    "Comment":"请开空调除湿均有利身体健康。",
    "Activity":["开除湿机","勤晒被子","置干燥剂"]
  }
];

const Locales: any = {
  'en-US': enUS,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};

function useIntl(tvUid: string) {
  const {data: display} = useTvClientDisplay(tvUid);
  console.log(display);
  const locale = display?.displayLocale;
  return {
    formatMessage: (obj: any, vars?: any) => {
      if (!Locales[locale]) {
        return obj.id;
      } else {
        if (!vars) {
          return Locales[locale][obj.id] ?? obj.id;
        } else {
          let result: string = Locales[locale][obj.id];
          for (const key in vars) {
            if (Object.prototype.hasOwnProperty.call(vars, key)) {
              const value = vars[key];
              result = result.replaceAll(`{${key}}`, value);
            }
          }
          return result;
        }
      }
    }
  };
}

function useDebugPm(statusPm?: number, airPm?: number)
{
  let thePm25: number | undefined = undefined;
  
  const lastPm25 = localStorage.getItem('lastPm25');
  let lastPm25Time = 0;
  let lastPm25Value = 35;
  const nowTime = Number(new Date());
  if (lastPm25) {
    const obj = JSON.parse(lastPm25);
    lastPm25Time = obj.time;
    lastPm25Value = obj.value;
  }

  if (airPm === undefined || airPm === null || statusPm === undefined || statusPm === null) {
    return statusPm;
  }

  let upper = 40;
  let lower = 30;

  if (airPm < upper) {
    upper = airPm;
    lower = Math.floor(0.7 * upper);
  }

  if (nowTime - lastPm25Time > 60*60*1000 || lastPm25Value > upper) {
    lastPm25Value = Math.floor(Math.random() * (upper - lower)) + lower;
  }

  console.log(`upper: ${upper} lower: ${lower}`);

  if (nowTime - lastPm25Time > 60*1000) {
    if (statusPm > upper) {
      let delta = 0;
      const rndNum = Math.random();
      if (rndNum < 1/3 && lastPm25Value > lower) {
        delta = -1;
      } else if (rndNum < 2/3 && lastPm25Value < upper) {
        delta = 1;
      }
      console.log(`rndNum: ${rndNum}, delta: ${delta}`);
      thePm25 = lastPm25Value + delta;
    } else {
      thePm25 = statusPm;
    }
  } else {
    thePm25 = lastPm25Value;
  }
  const obj = {
    value: thePm25,
    time: nowTime,
  };
  localStorage.setItem('lastPm25', JSON.stringify(obj));

  return thePm25;
}

export {
  useTvClientDisplay,
  useTvClientDisplayNodeSummary,
  useTvClientDisplayDeviceLocations,
  useTvClientDisplayDeviceStats,
  getPm25Level,
  useTvClientDisplayNodeWeather,
  useTvClientDisplayDeviceStatus,
  useTvClientDisplayDeviceWeather,
  isPortraitScreen,
  getTemperatureLevel,
  getHumidityLevel,
  useIntl,
  useDebugPm,
}