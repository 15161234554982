import { useDebugPm, useIntl, useTvClientDisplay, useTvClientDisplayDeviceStats, useTvClientDisplayNodeSummary, useTvClientDisplayNodeWeather } from '@/hooks/tvClient';
import React from 'react';
import QRCode from 'qrcode.react';
import BarWeatherBox from './components/BarWeatherBox';
import BarStatus from './components/BarStatus';
import BarPmCircle from './components/BarPmCircle';
import BarStatItem from './components/BarStatItem';
import BarPmBox from './components/BarPmBox';

interface BarNodePageProps {
  tvUid: string;
}

const BarNodePage: React.FC<BarNodePageProps> = (props) => {
  const {data: display} = useTvClientDisplay(props.tvUid);
  const {data: nodeSummary} = useTvClientDisplayNodeSummary(props.tvUid);
  const {data: deviceStats} = useTvClientDisplayDeviceStats(props.tvUid);
  const {data: weatherData} = useTvClientDisplayNodeWeather(props.tvUid);
  const intl = useIntl(props.tvUid);

  let thePm25: number | undefined = undefined;

  console.log('useDebugPm');
  if (display?.displayStyle.startsWith('Debug') && nodeSummary && weatherData) {
    console.log(weatherData.airPm25, nodeSummary.pm25);
    thePm25 = useDebugPm(nodeSummary.pm25, weatherData.airPm25);
  } else {
    thePm25 = nodeSummary?.pm25;
  }

  const devicesCount = (deviceStats?.statsDeviceType['X1000'] || 0) + (deviceStats?.statsDeviceType['X400'] || 0);
  console.log(devicesCount);

  return (<>
    <div style={{
      height: 240,
      width: '100%',
      position: 'relative',
      backgroundColor: 'rgba(0,0,0,0.5)',
    }}>
      <BarWeatherBox data={weatherData} display={display} tvUid={props.tvUid}/>
      {devicesCount > 0 && <BarStatus tvUid={props.tvUid}/>}
      <BarPmCircle pm25={thePm25} tvUid={props.tvUid}/>
      <div style={{
        position: 'absolute',
        left: 450,
        top: 120,
        transform: 'translate(0, -50%)',
      }}>
        <BarPmBox pm25={weatherData?.airPm25} label={intl.formatMessage({id: 'text.outdoor'})} tvUid={props.tvUid}/>
      </div>

      <div style={{
        position: 'absolute',
        left: 850,
        top: 40,
        textAlign: 'center',
        lineHeight: 1,
      }}>
        <div style={{
          marginBottom: 20,
          fontSize: 42,
        }}>{deviceStats?.statsDeviceType ? deviceStats?.statsDeviceType['X400'] || 0 : 0}{intl.formatMessage({id: 'text.deviceUnit'})}</div>
        <div style={{
          marginBottom: 20,
          fontSize: 38,
        }}>720 X400</div>
        <div style={{
          fontSize: 34,
        }}>{intl.formatMessage({id: 'text.disinfector'})}</div>
      </div>

      <div style={{
        position: 'absolute',
        right: 850,
        top: 40,
        textAlign: 'center',
        lineHeight: 1,
      }}>
        <div style={{
          marginBottom: 20,
          fontSize: 42,
        }}>{deviceStats?.statsDeviceType ? deviceStats?.statsDeviceType['X1000'] || 0 : 0}{intl.formatMessage({id: 'text.deviceUnit'})}</div>
        <div style={{
          marginBottom: 20,
          fontSize: 38,
        }}>720 X1000</div>
        <div style={{
          fontSize: 34,
        }}>{intl.formatMessage({id: 'text.disinfector'})}</div>
      </div>

      <div style={{
        width: 620,
        position: 'absolute',
        right: 130,
        top: 120,
        transform: 'translate(0, -50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <div>
          {nodeSummary?.totalWorkTime !== undefined && <BarStatItem 
            numberValue={nodeSummary?.totalWorkTime}
            unit={intl.formatMessage({id: 'unit.hour'})}
            label={intl.formatMessage({id: 'label.workTime'})}
          />}
          {nodeSummary?.totalPurify !== undefined && <BarStatItem 
            numberValue={nodeSummary?.totalPurify}
            precision={0}
            unit={intl.formatMessage({id: 'unit.purified'})}
            label={intl.formatMessage({id: 'label.purified'})}
          />}
        </div>
        <div style={{ marginTop: 25 }}>
          {nodeSummary?.totalPower !== undefined && <BarStatItem 
            numberValue={nodeSummary?.totalPower}
            unit={intl.formatMessage({id: 'unit.powerCost'})}
            label={intl.formatMessage({id: 'label.powerCost'})}
          />}
          {nodeSummary?.avgPurify !== undefined && <BarStatItem 
            numberValue={nodeSummary?.avgPurify}
            unit={intl.formatMessage({id: 'unit.purifiedAvg'})}
            label={intl.formatMessage({id: 'label.purifiedAvg'})}
              />}
        </div>
      </div>

      <div 
        className="qrcode-container"
        style={{
          right: 30,
          top: 70, 
          position: 'absolute'
        }}
      >
        <QRCode value={props.tvUid} includeMargin size={100}/>
      </div>
    </div>

  </>);
};


export default BarNodePage;