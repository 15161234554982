import { getPm25Level, useIntl } from '@/hooks/tvClient';
import React from 'react';

interface WeatherBoxProps {
  data?: any;
  tvUid: string;
}

const WeatherBox: React.FC<WeatherBoxProps> = ({data, tvUid}) => {
  const level: any = data?.airPm25 !== undefined ? getPm25Level(data.airPm25) : {};
  const intl = useIntl(tvUid);

  return (<>
    {data?.airPm25 !== undefined && <div style={{
      position: 'fixed',
      left: 30,
      top: 10,
      lineHeight: 1,
    }}>
      <div style={{
        fontSize: '60px',
      }}>
        {data.airPm25}
      </div>
      <div style={{
        fontSize: '20px',
        marginTop: 15,
      }}>
        {intl.formatMessage({id: 'text.outdoor'})} | PM2.5 | {intl.formatMessage({id: `level.pm25.${level.Level}`})}
      </div>
      <div style={{
        fontSize: '20px',
        marginTop: 15,
      }}>
        {data.locationName} | {data.weatherText}
      </div>
    </div>}
  </>);
}

export default WeatherBox;