import { Card } from "antd";
import React from "react";
import FlipNumbers from "react-flip-numbers";

interface NumberCardProps {
  numberValue?: number;
  precision?: number;
  unit: string;
  label: string;
  imgUrl: string;
}

const NumberCard: React.FC<NumberCardProps> = (props) => {
  return (
    <Card 
      bordered={false} 
      style={{textAlign: 'center', minWidth: 175, backgroundColor: 'rgba(0,0,0,0)'}}
      bodyStyle={{padding: '10px 15px'}}
    >
      <img src={props.imgUrl} width={55} height={55} style={{marginBottom: 5,}} />
      <FlipNumbers
        height={32}
        width={20}
        color="white"
        play
        duration={2}
        nonNumberStyle={{
          color: 'white',
          height: 28,
          fontSize: 'x-large',
          fontWeight: '700',
        }}
        numbers={props.numberValue?.toFixed(props.precision === undefined ? 2 : props.precision) ?? ''}
      />
      <div style={{
        color: 'white',
        marginTop: 5,
        fontSize: '17px',
      }}>
        <span style={{
          fontSize: '0.9em',
          marginLeft: '5px',
        }}>
          {props.unit}
        </span>
      </div>
      <div style={{
        color: 'white',
        marginTop: 5,
        fontSize: '17px',
      }}>
        <span>{props.label}</span>
      </div>
    </Card>
  );
}

export default NumberCard;