import { getPm25Level, useIntl } from '@/hooks/tvClient';
import React from 'react';

interface BarPmCircleProps {
  pm25?: number;
  tvUid: string;
}

const BarPmCircle: React.FC<BarPmCircleProps> = ({pm25, tvUid}) => {
  const level: any = pm25 ? getPm25Level(pm25) : {};
  const intl = useIntl(tvUid);
  return (<>
    {pm25 !== undefined && <div style={{
      height: 220,
      width: 220,
      borderRadius: 200,
      border: 'solid 15px',
      borderColor: level.Color,
      position: 'absolute',
      left: '50%',
      top: 10,
      transform: 'translate(-50%, 0)',
      textAlign: 'center',
      lineHeight: 1,
      paddingTop: 30,
    }}>
      <div style={{fontSize: '24px'}}>{intl.formatMessage({id: 'text.indoor'})}</div>
      <div style={{fontSize: '40px', marginTop: 20}}>{intl.formatMessage({id: `level.pm25.${level.Level}`})}</div>
      <div style={{fontSize: '60px', marginTop: 5}}>{pm25}</div>
    </div>}
  </>);
}

export default BarPmCircle;