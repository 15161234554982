import { getPm25Level, useIntl } from '@/hooks/tvClient';
import moment from 'moment';
import React from 'react';

interface BarWeatherBoxProps {
  data?: any;
  display?: any;
  tvUid: string;
}

const BarWeatherBox: React.FC<BarWeatherBoxProps> = ({data, display, tvUid}) => {
  const level: any = data?.airPm25 !== undefined ? getPm25Level(data.airPm25) : {};
  const vertialGap = 15;
  const intl = useIntl(tvUid);
  return (<>
    <div style={{
      position: 'absolute',
      left: 30,
      top: 30,
      lineHeight: 1,
      fontSize: '30px',
    }}>
      <div style={{
      }}>
        {moment().format(intl.formatMessage({id: 'format.date.moment'}))}
      </div>
      <div style={{
        marginTop: vertialGap,
      }}>
        {data?.locationName}{data?.weatherText && ` | ${data?.weatherText}`}
      </div>
      {display && <div style={{
        marginTop: vertialGap,
      }}>
        {display.displayType === 'Node' ? 
          <span>{display.displayPath[0]}</span> : 
          <><span>{display.displayPath[1]}</span></>
        }
      </div>}
    </div>
  </>);
}

export default BarWeatherBox;