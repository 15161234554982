import { getHumidityLevel, getPm25Level, getTemperatureLevel, useDebugPm, useIntl, useTvClientDisplay, useTvClientDisplayDeviceStatus, useTvClientDisplayDeviceWeather } from '@/hooks/tvClient';
import React from 'react';
import QRCode from 'qrcode.react';
import PmBigCircle from './components/PmBigCircle';
import WeatherBox from './components/WeatherBox';
import MiddleTopStatus from './components/MiddleTopStatus';
import DeviceBox from './components/DeviceBox';
import NumberCard from './components/NumberCard';
import GaugeCard from './components/GaugeCard';
import FilterLifeCircle from './components/FilterLifeCircle';
import {Row, Col} from 'antd';
import moment from 'moment';
import { Images } from '@/hooks/images';

interface DevicePageProps {
  tvUid: string;
  portrait?: boolean;
}

const DevicePage: React.FC<DevicePageProps> = (props) => {
  const {data: display} = useTvClientDisplay(props.tvUid);
  const {data: deviceStatus} = useTvClientDisplayDeviceStatus(props.tvUid);
  const {data: weatherData} = useTvClientDisplayDeviceWeather(props.tvUid);
  const intl = useIntl(props.tvUid);

  let thePm25: number | undefined = undefined;

  console.log('useDebugPm');
  if (display?.displayStyle.startsWith('Debug') && deviceStatus && weatherData) {
    console.log(weatherData.airPm25, deviceStatus.pm25);
    thePm25 = useDebugPm(deviceStatus.pm25, weatherData.airPm25);
  } else {
    thePm25 = deviceStatus?.pm25;
  }

  const deviceSwitchWidth = 150;

  const anion = deviceStatus?.online ? (deviceStatus?.powerOn ? deviceStatus?.anionOn : false) : undefined;
  const uv = deviceStatus?.online ? (deviceStatus?.powerOn ? deviceStatus?.uvOn : false) : undefined;
  const modeIndex = deviceStatus?.online ? (deviceStatus?.powerOn ? deviceStatus?.mode : -1) : -2;
  const Modes: any = {
    [-2]: {
      icon: 'off',
      text: '--',
    },
    [-1]: {
      icon: 'off',
      text: intl.formatMessage({id: 'text.powerOff'}),
    },
    [0]: {
      icon: 'speed_on',
      text: intl.formatMessage({id: 'text.manual'}),
    },
    [1]: {
      icon: 'auto_on',
      text: intl.formatMessage({id: 'text.auto'}),
    },
    [2]: {
      icon: 'sleep_on',
      text: intl.formatMessage({id: 'text.sleep'}),
    },
  };
  const mode = modeIndex !== undefined ? Modes[modeIndex] : undefined;
  const shift = deviceStatus?.shift;

  console.log(deviceStatus?.powerOn);

  const videoEl = document.getElementById('bgvid');
  if (videoEl !== null) {
    if (deviceStatus?.powerOn === true) {
      videoEl.play();
    } else {
      videoEl.pause();
    }
  }

  const scene = deviceStatus?.online ? 
    (deviceStatus?.applyScene ? 
      (deviceStatus?.sceneInterruptWaitSeconds !== undefined
         && deviceStatus?.sceneInterruptWaitSeconds > 0 ? 
           'pause'
         : 'apply')
       : 'stop')
     : undefined;

  return (
  props.portrait ? <>
    <div style={{
      width: '100%',
      height: 320,
      backgroundColor: 'rgba(255,255,255,0.1)',
      position: 'relative',
    }}
    >
      <div style={{
        position: 'absolute',
        left: 20,
        top: 40,
        lineHeight: 1,
        fontSize: 24,
      }}>
        <div style={{
        }}>
          {moment().format(intl.formatMessage({id: 'format.date.moment'}))}
        </div>
        <div style={{
          marginTop: 15,
        }}>
          {weatherData?.locationName}{weatherData?.weatherText && ` | ${weatherData?.weatherText}`}
        </div>
      </div>

      <div style={{
        position: 'absolute',
        right: 140,
        top: 30,
      }}>
        <div style={{
          textAlign: 'center',
        }}>
          <div style={{display: 'inline-block', verticalAlign: 'middle', fontSize: 22,}}>
            <div>{intl.formatMessage({id: 'text.outdoor'})} | PM2.5</div>
            <div style={{textAlign: 'right'}}>{weatherData?.airPm25 ? intl.formatMessage({id: `level.pm25.${getPm25Level(weatherData?.airPm25)?.Level}`}) : ''}</div>
          </div>
          <div style={{
            display: 'inline-block',
            height: 50,
            verticalAlign: 'middle',
            width: 2,
            margin: '0 30px',
            backgroundColor: 'white',
          }}></div>
          <div style={{
            display: 'inline-block',
            textAlign: 'center',
            lineHeight: 1,
          }}>
            <div style={{fontSize: 42, marginTop: 10}}>{weatherData?.airPm25}</div>
            <div style={{
              width: 80,
              height: 8,
              borderRadius: 12,
              backgroundColor: weatherData?.airPm25 ? getPm25Level(weatherData?.airPm25)?.Color : 'rgba(0,0,0,0)',
              margin: '10px auto',}}>
            </div>
          </div>
        </div>
      </div>

      <div 
        className="qrcode-container"
        style={{
          right: 20,
          top: 30, 
          position: 'absolute',
        }}
      >
        <QRCode value={props.tvUid} includeMargin size={80}/>
      </div>

      <div style={{
        textAlign: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 100,
        fontSize: 55,
      }}
      >
        {display?.displayPath[1]}
      </div>
      <div style={{
        position: 'absolute',
        left: '50%',
        bottom: 20,
        width: '100%',
        textAlign: 'center',
        transform: 'translate(-50%, 0)',
      }}>
        <MiddleTopStatus deviceStatus={deviceStatus} tvUid={props.tvUid}/>
      </div>
    </div>
    <Row style={{
      height: 555,
      margin: '15px 15px 0 15px',
    }}>
      <Col style={{
        height: '100%',
        width: 280,
        marginRight: 15,
      }}>
        <Row style={{
          height: 175,
          width: '100%',
          marginBottom: 15,
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
          position: 'relative',
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}>
            {deviceStatus?.totalPurified !== undefined && deviceStatus?.online === true &&
            <NumberCard 
              numberValue={deviceStatus?.totalPurified}
              precision={0}
              unit={intl.formatMessage({id: 'unit.purified'})}
              label={intl.formatMessage({id: 'label.purified'})}
              imgUrl={Images.totalPurify}
            />}
          </div>
        </Row>
        <Row style={{
          height: 175,
          width: '100%',
          marginBottom: 15,
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}>
            {deviceStatus?.totalPowerCost !== undefined && deviceStatus?.online === true &&
            <NumberCard 
              numberValue={deviceStatus?.totalPowerCost / 1000}
              unit={intl.formatMessage({id: 'unit.powerCost'})}
              label={intl.formatMessage({id: 'label.powerCost'})}
              imgUrl={Images.totalPower}
            />}
          </div>
        </Row>
        <Row style={{
          height: 175,
          width: '100%',
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}>
            {deviceStatus?.filterLife !== undefined && deviceStatus?.online === true && <div style={{
              marginTop: 12,
            }}>
              <FilterLifeCircle percentValue={deviceStatus.filterLife} label={intl.formatMessage({id: 'text.filterRemain'})}/>
            </div>}
          </div>
        </Row>
      </Col>
      <Col style={{
        height: '100%',
        width: 575,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
        position: 'relative',
      }}>
        {deviceStatus?.online && <div style={{
          zoom: 1.25,
          margin: '23px 31px',
        }}>
          <PmBigCircle pm25={thePm25} tvUid={props.tvUid}/>
        </div>}
        {display?.displayStyle !== 'FullScreen' && display?.displayStyle !== 'DebugFullScreen' && deviceStatus?.temperature !== undefined && <div style={{
          position: 'absolute',
          left: 20,
          bottom: 15,
          textAlign: 'center',
          fontSize: 16,
        }}>
          <div>{intl.formatMessage({id: 'temperature.indoor'})}</div>
          <div>{deviceStatus?.temperature}℃ | {intl.formatMessage({id: `level.temperature.${getTemperatureLevel(deviceStatus?.temperature)?.Level}`})}</div>
        </div>}
        {display?.displayStyle !== 'FullScreen' && display?.displayStyle !== 'DebugFullScreen' && deviceStatus?.humidity !== undefined && <div style={{
          position: 'absolute',
          right: 20,
          bottom: 15,
          textAlign: 'center',
          fontSize: 16,
        }}>
          <div>{intl.formatMessage({id: 'humidity.indoor'})}</div>
          <div>{deviceStatus?.humidity}% | {intl.formatMessage({id: `level.humidity.${getHumidityLevel(deviceStatus?.humidity)?.Level}`})}</div>
        </div>}
      </Col>
    </Row>

    {(display?.displayStyle === 'FullScreen' || display?.displayStyle === 'DebugFullScreen') ? 
    <Row style={{
      height: 575,
      margin: '15px 15px 0 15px',
    }}>
      <Col style={{
        width: 280,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
        marginRight: 15,
      }}>
        {display && <div style={{
          textAlign: 'center',
        }}>
          <div style={{
            marginTop: 70,
            marginBottom: 30,
            fontSize: 17,
          }}>{intl.formatMessage({id: 'text.disinfector'})} {display?.deviceTypeShort}<br/>SN: {display?.displayPath[0]}</div>
          <img src={Images.device(display?.deviceTypeShort)} width={190} height={380}/>
        </div>}
      </Col>
      <Col style={{
        width: 280,
        marginRight: 15,
      }}>
        <Row style={{
          height: 280,
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
          marginBottom: 15,
          position: 'relative',
        }}>
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zoom: 1.2,
          }}>
            {display?.deviceTypeShort === 'X1000' && deviceStatus?.temperature !== undefined && deviceStatus?.online === true && <div>
            <GaugeCard 
              gaugeValue={deviceStatus?.temperature} 
              colors={['#bae4fc', '#58dd83', '#fdd379', '#f15b5d']}
              levels={{
                18: intl.formatMessage({id: 'temperature.gauge.cold'}),
                28: intl.formatMessage({id: 'temperature.gauge.comfort'}),
                37: intl.formatMessage({id: 'temperature.gauge.hot'}),
              }}
              maxValue={45}
              gaugeLabel={intl.formatMessage({id: 'temperature.indoor'})}
              gaugeUnit='℃'
            /></div>}
            {display?.deviceTypeShort === 'X400' && mode && shift !== undefined && <div style={{
              textAlign: 'center',
              width: deviceSwitchWidth,
            }}>
              <img src={Images.modeIcon(mode.icon)} width={40} height={40}/>
              <div style={{
                marginTop: 10,
                fontSize: 16,
              }}>{mode.text}{deviceStatus?.online && deviceStatus?.online && deviceStatus?.powerOn ? ` | ${intl.formatMessage({id: 'text.shift'}, {shift: shift+1})}` : ''}</div>
            </div>}
          </div>
        </Row>
        <Row style={{
          height: 280,
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
          position: 'relative',
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',      
          }}>
            {display?.deviceTypeShort === 'X1000' && <>
              {mode && shift !== undefined && <div style={{
                textAlign: 'center',
                width: deviceSwitchWidth,
              }}>
                <img src={Images.modeIcon(mode.icon)} width={40} height={40}/>
                <div style={{
                  marginTop: 10,
                  fontSize: 16,
                }}>{mode.text}{deviceStatus?.online && deviceStatus?.powerOn ? ` | ${intl.formatMessage({id: 'text.shift'}, {shift: shift+1})}` : ''}</div>
              </div>}
              <div style={{
                textAlign: 'center',
                width: deviceSwitchWidth,
                marginTop: 40,
              }}>
                <img src={Images.uv(uv)} width={40} height={40}/>
                <div style={{
                  marginTop: 10,
                  fontSize: 16,
                }}>{uv === true ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (uv === false ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
              </div></>
            }
            {display?.deviceTypeShort === 'X400' && <div style={{
              textAlign: 'center',
              width: deviceSwitchWidth,
              marginTop: 40,
            }}>
              <img src={Images.uv(uv)} width={40} height={40}/>
              <div style={{
                marginTop: 10,
                fontSize: 16,
              }}>{uv === true ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (uv === false ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
            </div>}
          </div>
        </Row>
      </Col>
      <Col style={{
        width: 280,
      }}>
        <Row style={{
          height: 280,
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
          marginBottom: 15,          
          position: 'relative',
        }}>
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zoom: 1.2,
          }}>
            {display?.deviceTypeShort === 'X1000' && deviceStatus?.humidity !== undefined && deviceStatus?.online === true && <div>
            <GaugeCard 
              gaugeValue={deviceStatus?.humidity} 
              colors={['#fdd379', '#a8e22c', '#58dd83', '#bae4fc']}
              levels={{
                20: intl.formatMessage({id: 'humidity.gauge.dry'}),
                40: intl.formatMessage({id: 'humidity.gauge.littleDry'}),
                65: intl.formatMessage({id: 'humidity.gauge.comfort'}),
              }}
              maxValue={100}
              gaugeLabel={intl.formatMessage({id: 'humidity.indoor'})}
              gaugeUnit='%'
            /></div>}
            {display?.deviceTypeShort === 'X400' && <div style={{
              textAlign: 'center',
              width: deviceSwitchWidth,
            }}>
              <img src={Images.anion(anion)} width={40} height={40}/>
              <div style={{
                marginTop: 10,
                fontSize: 16,
              }}>{anion === true ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (anion === false ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
            </div>}
          </div>
        </Row>
        <Row style={{
          height: 280,
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
          position: 'relative',
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',      
          }}>
            {display?.deviceTypeShort === 'X1000' && <>
              <div style={{
                textAlign: 'center',
                width: deviceSwitchWidth,
              }}>
                <img src={Images.anion(anion)} width={40} height={40}/>
                <div style={{
                  marginTop: 10,
                  fontSize: 16,
                }}>{anion === true ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (anion === false ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
              </div>
              <div style={{
                textAlign: 'center',
                width: deviceSwitchWidth,
                marginTop: 40,
              }}>
                <img src={Images.scene(scene === 'apply')} width={40} height={40}/>
                <div style={{
                  marginTop: 10,
                  fontSize: 16,
                }}>{scene === undefined ? '--' : `${intl.formatMessage({id: 'device.list.scene'})} | ${scene === 'apply' ? intl.formatMessage({id: 'scene.stats.apply'}) : (scene === 'pause' ? intl.formatMessage({id: 'scene.stats.pause'}) : intl.formatMessage({id: 'scene.stats.stop'}))}`}</div>
              </div>
            </>}
            {display?.deviceTypeShort === 'X400' && <div style={{
                textAlign: 'center',
                width: deviceSwitchWidth,
                marginTop: 40,
              }}>
                <img src={Images.scene(scene === 'apply')} width={40} height={40}/>
                <div style={{
                  marginTop: 10,
                  fontSize: 16,
                }}>{scene === undefined ? '--' : `${intl.formatMessage({id: 'device.list.scene'})} | ${scene === 'apply' ? intl.formatMessage({id: 'scene.stats.apply'}) : (scene === 'pause' ? intl.formatMessage({id: 'scene.stats.pause'}) : intl.formatMessage({id: 'scene.stats.stop'}))}`}</div>
              </div>}
          </div>
        </Row>
      </Col>
    </Row>
    : <Row style={{
      height: 175,
      margin: '15px 15px 0 15px',
    }}>
      <Col style={{
        width: 280,
        marginRight: 15,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
      }}>
        {display && <div style={{marginTop: 20}}>
          <img src={Images.device(display?.deviceTypeShort)} width={70} height={140} style={{marginLeft: 20}}/>
          <div style={{
            display: 'inline-block',
            marginLeft: 20,
            verticalAlign: 'middle',
          }}>
            <div style={{
              fontSize: 17,
            }}>{intl.formatMessage({id: 'text.disinfector'})} {display?.deviceTypeShort}</div>
            <div style={{
              marginTop: 10,
              fontSize: 17,
            }}>SN: {display?.displayPath[0]}</div>
          </div>
        </div>}
      </Col>
      <Col style={{
        width: 575,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
        position: 'relative',
      }}>
        <div style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          fontSize: 16,
        }}>
          {mode && shift !== undefined && <div style={{
            textAlign: 'center',
            width: 140,
          }}>
            <img src={Images.modeIcon(mode.icon)} width={40} height={40}/>
            <div style={{
              marginTop: 10,
            }}>{mode.text}{deviceStatus?.online && deviceStatus?.powerOn ? ` | ${intl.formatMessage({id: 'text.shift'}, {shift: shift+1})}` : ''}</div>
          </div>}
          <div style={{
            textAlign: 'center',
            width: 140,
          }}>
            <img src={Images.anion(anion)} width={40} height={40}/>
            <div style={{
              marginTop: 10,
            }}>{anion === true ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (anion === false ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
          </div>
          <div style={{
            textAlign: 'center',
            width: 140,
          }}>
            <img src={Images.uv(uv)} width={40} height={40}/>
            <div style={{
              marginTop: 10,
            }}>{uv === true ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (uv === false ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
          </div>
          <div style={{
            textAlign: 'center',
            width: 140,
          }}>
            <img src={Images.scene(scene === 'apply')} width={40} height={40}/>
            <div style={{
              marginTop: 10,
            }}>{scene === undefined ? '--' : `${intl.formatMessage({id: 'device.list.scene'})} | ${scene === 'apply' ? intl.formatMessage({id: 'scene.stats.apply'}) : (scene === 'pause' ? intl.formatMessage({id: 'scene.stats.pause'}) : intl.formatMessage({id: 'scene.stats.stop'}))}`}</div>
          </div>
        </div>
      </Col>
    </Row>
    }
  </> : <>
    <WeatherBox data={weatherData} tvUid={props.tvUid}/>
    <DeviceBox display={display} tvUid={props.tvUid}/>

    <div style={{
      position: 'fixed',
      left: '50%',
      top: '80px',
      transform: 'translate(-50%, 0)',      
    }}>
      <MiddleTopStatus deviceStatus={deviceStatus} tvUid={props.tvUid}/>
    </div>

    {deviceStatus?.online === true && <div style={{
      position: 'fixed',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }}>
      <PmBigCircle pm25={thePm25} tvUid={props.tvUid}/>
    </div>}

    <div style={{
      width: 175,
      position: 'fixed',
      left: 200,
      top: '50%',
      transform: 'translate(0, -50%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      {deviceStatus?.totalPurified !== undefined && deviceStatus?.online === true &&
        <NumberCard 
          numberValue={deviceStatus?.totalPurified}
          precision={0}
          unit={intl.formatMessage({id: 'unit.purified'})}
          label={intl.formatMessage({id: 'label.purified'})}
          imgUrl={Images.totalPurify}
        />}
      {deviceStatus?.temperature !== undefined && deviceStatus?.online === true && <div style={{
        marginTop: 50,
      }}>
      <GaugeCard 
        gaugeValue={deviceStatus?.temperature} 
        colors={['#bae4fc', '#58dd83', '#fdd379', '#f15b5d']}
        levels={{
          18: intl.formatMessage({id: 'temperature.gauge.cold'}),
          28: intl.formatMessage({id: 'temperature.gauge.comfort'}),
          37: intl.formatMessage({id: 'temperature.gauge.hot'}),
        }}
        maxValue={45}
        gaugeLabel={intl.formatMessage({id: 'temperature.indoor'})}
        gaugeUnit='℃'
      /></div>}
      {display && <div style={{
        marginTop: 50,
        textAlign: 'center',
      }}>
        <img src={Images.device(display?.deviceTypeShort)} width={70} height={140}/>
        <div style={{
          marginTop: 20,
          fontSize: 17,
        }}>{intl.formatMessage({id: 'text.disinfector'})} {display?.deviceTypeShort}</div>
      </div>}
    </div>

    <div style={{
      width: 175,
      position: 'fixed',
      right: 200,
      top: '50%',
      transform: 'translate(0, -50%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      {deviceStatus?.totalPowerCost !== undefined && deviceStatus?.online === true &&
        <NumberCard 
          numberValue={deviceStatus?.totalPowerCost / 1000}
          unit={intl.formatMessage({id: 'unit.powerCost'})}
          label={intl.formatMessage({id: 'label.powerCost'})}
          imgUrl={Images.totalPower}
        />}
      {deviceStatus?.humidity !== undefined && deviceStatus?.online === true && <div style={{
        marginTop: 50,
      }}>
        <GaugeCard 
          gaugeValue={deviceStatus?.humidity} 
          colors={['#fdd379', '#a8e22c', '#58dd83', '#bae4fc']}
          levels={{
            20: intl.formatMessage({id: 'humidity.gauge.dry'}),
            40: intl.formatMessage({id: 'humidity.gauge.littleDry'}),
            65: intl.formatMessage({id: 'humidity.gauge.comfort'}),
          }}
          maxValue={100}
          gaugeLabel={intl.formatMessage({id: 'humidity.indoor'})}
          gaugeUnit='%'
        /></div>}
      {deviceStatus?.filterLife !== undefined && deviceStatus?.online === true && <div style={{
        marginTop: 50,
      }}>
        <FilterLifeCircle percentValue={deviceStatus.filterLife} label={intl.formatMessage({id: 'text.filterRemain'})}/>
      </div>}
    </div>

    <div style={{
      position: 'fixed',
      width: 600,
      bottom: 120,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      left: '50%',
      transform: 'translate(-50%,0)',
    }}>
      {mode && shift !== undefined && <div style={{
        textAlign: 'center',
        width: deviceSwitchWidth,
      }}>
        <img src={Images.modeIcon(mode.icon)} width={40} height={40}/>
        <div style={{
          marginTop: 10,
          fontSize: 16,
        }}>{mode.text}{deviceStatus?.online && deviceStatus?.powerOn ? ` | ${intl.formatMessage({id: 'text.shift'}, {shift: shift+1})}` : ''}</div>
      </div>}
      <div style={{
        textAlign: 'center',
        width: deviceSwitchWidth,
      }}>
        <img src={Images.anion(anion)} width={40} height={40}/>
        <div style={{
          marginTop: 10,
          fontSize: 16,
        }}>{anion === true ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (anion === false ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
      </div>
      <div style={{
        textAlign: 'center',
        width: deviceSwitchWidth,
      }}>
        <img src={Images.uv(uv)} width={40} height={40}/>
        <div style={{
          marginTop: 10,
          fontSize: 16,
        }}>{uv === true ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (uv === false ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
      </div>
      <div style={{
        textAlign: 'center',
        width: deviceSwitchWidth,
      }}>
        <img src={Images.scene(scene === 'apply')} width={40} height={40}/>
        <div style={{
          marginTop: 10,
          fontSize: 16,
        }}>{scene === undefined ? '--' : `${intl.formatMessage({id: 'device.list.scene'})} | ${scene === 'apply' ? intl.formatMessage({id: 'scene.stats.apply'}) : (scene === 'pause' ? intl.formatMessage({id: 'scene.stats.pause'}) : intl.formatMessage({id: 'scene.stats.stop'}))}`}</div>
      </div>
    </div>

    <div 
      className="qrcode-container"
      style={{
        left: 30,
        bottom: 15, 
        position: 'fixed'
      }}
    >
      <QRCode value={props.tvUid} includeMargin size={80}/>
    </div>
  </>);
};


export default DevicePage;