import { Images } from '@/hooks/images';
import { useIntl } from '@/hooks/tvClient';
import React from 'react';

interface BarStatusProps {
  deviceStatus?: any;
  tvUid: string;
}

const BarStatus: React.FC<BarStatusProps> = ({deviceStatus, tvUid}) => {
  const intl = useIntl(tvUid);
  let text: string = '';
  let icon: string = '';
  if (deviceStatus && deviceStatus.online === false) {
    text = intl.formatMessage({id: 'text.deviceOffline'});
    icon = Images.lixian;
  } else if (deviceStatus && deviceStatus.powerOn === false) {
    text = intl.formatMessage({id: 'text.deviceStandby'});
    icon = Images.daiji;
  } else {
    text = intl.formatMessage({id: 'text.disinfecting'});
    icon = Images.titleClean;
  }
  return (<>
    <div style={{
      position: 'absolute',
      fontSize: '38px',
      left: 30,
      top: 150,
    }}>
      <span style={{verticalAlign: 'middle'}}>
        {text} 
      </span>
      <img src={icon} width={70} height={70} style={{marginLeft: 10,}} />
    </div>
  </>);
}

export default BarStatus;