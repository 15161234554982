import { getPm25Level, useIntl } from '@/hooks/tvClient';
import React from 'react';

interface BarPmBoxProps {
  pm25?: number;
  label?: string;
  tvUid: string;
}

const BarPmBox: React.FC<BarPmBoxProps> = ({pm25, label, tvUid}) => {
  const level: any = pm25 ? getPm25Level(pm25) : {};
  const intl = useIntl(tvUid);
  return (<>
    {pm25 !== undefined ? 
      <div style={{
        textAlign: 'center',
        lineHeight: 1,
      }}>
        <div style={{fontSize: '26px'}}>{label} PM2.5 | {intl.formatMessage({id: `level.pm25.${level.Level}`})}</div>
        <div style={{fontSize: '60px', marginTop: 10}}>{pm25}</div>
        <div style={{
          width: 200,
          height: 12,
          borderRadius: 12,
          backgroundColor: level.Color,
          margin: '10px auto',}}>
        </div>
      </div>
    : 
      <div style={{
        textAlign: 'center',
        lineHeight: 1,
      }}>
        <div style={{fontSize: '26px'}}>{label} PM2.5 | --</div>
        <div style={{fontSize: '60px', marginTop: 10}}>--</div>
        <div style={{
          width: 200,
          height: 12,
          borderRadius: 12,
          backgroundColor: 'rgba(0,0,0,0)',
          margin: '10px auto',}}>
        </div>
      </div>
    }
  </>);
}

export default BarPmBox;