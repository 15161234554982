import { getPm25Level, useTvClientDisplay, useTvClientDisplayDeviceLocations, useTvClientDisplayDeviceStats, useTvClientDisplayNodeSummary, useTvClientDisplayNodeWeather, useIntl, useDebugPm } from '@/hooks/tvClient';
import React from 'react';
import QRCode from 'qrcode.react';
import NumberCard from './components/NumberCard';
// import MapCard from './components/MapCard';
import ChartColumn from './components/ChartColumn';
import WeatherBox from './components/WeatherBox';
import SpaceBox from './components/SpaceBox';
import MiddleTopStatus from './components/MiddleTopStatus';
import PmBigCircle from './components/PmBigCircle';
import {Row, Col} from 'antd';
import moment from 'moment';
import { Images } from '@/hooks/images';

interface NodePageProps {
  tvUid: string;
  portrait?: boolean;
}

const NodePage: React.FC<NodePageProps> = (props) => {
  const {data: display} = useTvClientDisplay(props.tvUid);
  const {data: nodeSummary} = useTvClientDisplayNodeSummary(props.tvUid);
  const {data: deviceLocations} = useTvClientDisplayDeviceLocations(props.tvUid);
  const {data: deviceStats} = useTvClientDisplayDeviceStats(props.tvUid);
  const {data: weatherData} = useTvClientDisplayNodeWeather(props.tvUid);
  const intl = useIntl(props.tvUid);

  let thePm25: number | undefined = undefined;

  if (display?.displayStyle.startsWith('Debug') && nodeSummary && weatherData) {
    console.log(weatherData.airPm25, nodeSummary.pm25);
    thePm25 = useDebugPm(nodeSummary.pm25, weatherData.airPm25);
  } else {
    thePm25 = nodeSummary?.pm25;
  }

  const devicesCount = (deviceStats?.statsDeviceType['X1000'] || 0) + (deviceStats?.statsDeviceType['X400'] || 0);
  console.log(`devicesCount: ${devicesCount}`);

  const videoEl = document.getElementById('bgvid');
  if (videoEl !== null) {
    if (devicesCount !== undefined && !isNaN(devicesCount) && devicesCount > 0) {
      videoEl.play();
    } else {
      videoEl.pause();
    }
  }

  return (
  props.portrait ? <>
    <div style={{
      width: '100%',
      height: 320,
      backgroundColor: 'rgba(255,255,255,0.1)',
      position: 'relative',
    }}
    >
      <div style={{
        position: 'absolute',
        left: 20,
        top: 40,
        lineHeight: 1,
        fontSize: 24,
      }}>
        <div style={{
        }}>
          {moment().format(intl.formatMessage({id: 'format.date.moment'}))}
        </div>
        <div style={{
          marginTop: 15,
        }}>
          {weatherData?.locationName}{weatherData?.weatherText && ` | ${weatherData?.weatherText}`}
        </div>
      </div>

      <div style={{
        position: 'absolute',
        right: 140,
        top: 30,
      }}>
        <div style={{
          textAlign: 'center',
        }}>
          <div style={{display: 'inline-block', verticalAlign: 'middle', fontSize: 22,}}>
            <div>{intl.formatMessage({id: 'text.outdoor'})} | PM2.5</div>
            <div style={{textAlign: 'right'}}>{weatherData?.airPm25 ? intl.formatMessage({id: `level.pm25.${getPm25Level(weatherData?.airPm25)?.Level}`}) : ''}</div>
          </div>
          <div style={{
            display: 'inline-block',
            height: 50,
            verticalAlign: 'middle',
            width: 2,
            margin: '0 30px',
            backgroundColor: 'white',
          }}></div>
          <div style={{
            display: 'inline-block',
            textAlign: 'center',
            lineHeight: 1,
          }}>
            <div style={{fontSize: 42, marginTop: 10}}>{weatherData?.airPm25}</div>
            <div style={{
              width: 80,
              height: 8,
              borderRadius: 12,
              backgroundColor: weatherData?.airPm25 ? getPm25Level(weatherData?.airPm25)?.Color : 'rgba(0,0,0,0)',
              margin: '10px auto',}}>
            </div>
          </div>
        </div>
      </div>

      <div 
        className="qrcode-container"
        style={{
          right: 20,
          top: 30, 
          position: 'absolute',
        }}
      >
        <QRCode value={props.tvUid} includeMargin size={80}/>
      </div>

      <div style={{
        textAlign: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 100,
        fontSize: 55,
      }}
      >
        {display?.displayPath[0]}
      </div>
      {devicesCount > 0 && <div style={{
        position: 'absolute',
        left: '50%',
        bottom: 20,
        transform: 'translate(-50%, 0)',
      }}>
        <MiddleTopStatus tvUid={props.tvUid}/>
      </div>}
    </div>
    <Row style={{
      height: 555,
      margin: '15px 15px 0 15px',
    }}>
      <Col style={{
        height: '100%',
        width: 280,
        marginRight: 15,
      }}>
        <Row style={{
          height: 175,
          width: '100%',
          marginBottom: 15,
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
          position: 'relative',
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
          >
            {nodeSummary?.totalWorkTime !== undefined && <NumberCard 
              numberValue={nodeSummary?.totalWorkTime}
              unit={intl.formatMessage({id: 'unit.hour'})}
              label={intl.formatMessage({id: 'label.workTime'})}
              imgUrl={Images.totalWorkTime}
            />}
          </div>
        </Row>
        <Row style={{
          height: 175,
          width: '100%',
          marginBottom: 15,
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
          >
            {nodeSummary?.totalPurify !== undefined && <NumberCard 
              numberValue={nodeSummary?.totalPurify}
              precision={0}
              unit={intl.formatMessage({id: 'unit.purified'})}
              label={intl.formatMessage({id: 'label.purified'})}
              imgUrl={Images.totalPurify}
            />}
          </div>
        </Row>
        <Row style={{
          height: 175,
          width: '100%',
          backgroundColor: 'rgba(255,255,255,0.1)',
          borderRadius: 20,
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
          >
            {nodeSummary?.avgPurify !== undefined && <NumberCard 
              numberValue={nodeSummary?.avgPurify}
              unit={intl.formatMessage({id: 'unit.purifiedAvg'})}
              label={intl.formatMessage({id: 'label.purifiedAvg'})}
              imgUrl={Images.avgPurify}
            />}
          </div>
        </Row>
      </Col>
      <Col style={{
        height: '100%',
        width: 575,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
      }}>
        <div style={{
          zoom: 1.25,
          margin: '23px 31px',
        }}>
          <PmBigCircle pm25={thePm25} tvUid={props.tvUid}/>
        </div>
      </Col>
    </Row>
    <Row style={{
      height: 175,
      margin: '15px 15px 0 15px',
    }}>
      <Col style={{
        height: '100%',
        width: 280,
        marginRight: 15,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
      }}>
        <div style={{
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, 0)',
        }}
        >
          {nodeSummary?.totalPower !== undefined && <NumberCard 
            numberValue={nodeSummary?.totalPower}
            unit={intl.formatMessage({id: 'unit.powerCost'})}
            label={intl.formatMessage({id: 'label.powerCost'})}
            imgUrl={Images.totalPower}
          />}
        </div>
      </Col>
      <Col style={{
        height: '100%',
        width: 280,
        marginRight: 15,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
      }}>
        <img src={Images.device('X400')} width={65} height={130} style={{
          margin: '25px',
        }}
        />
        <div style={{
          display: 'inline-block',
          fontSize: 17,
          verticalAlign: 'middle',
        }}>
          <div>{intl.formatMessage({id: 'text.disinfector'})} X400<br/>{intl.formatMessage({id: 'text.deployed'})}</div>
          <div style={{fontSize: 30}}>{deviceStats?.statsDeviceType ? deviceStats?.statsDeviceType['X400'] || 0 : 0}{intl.formatMessage({id: 'text.deviceUnit'})}</div>
        </div>
      </Col>
      <Col style={{
        height: '100%',
        width: 280,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
      }}>
        <img src={Images.device('X1000')} width={65} height={130} style={{
          margin: '25px',
        }}
        />
        <div style={{
          display: 'inline-block',
          fontSize: 17,
          verticalAlign: 'middle',
        }}>
          <div>{intl.formatMessage({id: 'text.disinfector'})} X1000<br/>{intl.formatMessage({id: 'text.deployed'})}</div>
          <div style={{fontSize: 30}}>{deviceStats?.statsDeviceType ? deviceStats?.statsDeviceType['X1000'] || 0 : 0}{intl.formatMessage({id: 'text.deviceUnit'})}</div>
        </div>
      </Col>
    </Row>
    <Row style={{
      height: 490,
      margin: '15px 15px 0 15px',
    }}>
      {/* <Col style={{
        height: '100%',
        width: '100%',
        // marginRight: 15,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
      }}> */}
        {/* {deviceLocations && <MapCard title={intl.formatMessage({id: 'device.locations'}, {count: deviceLocations?.length})} devicesLocations={deviceLocations} height={420}/>} */}
      {/* </Col> */}
      {/* <Col style={{
        height: '100%',
        width: 427,
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: 20,
      }}>
        <ChartColumn title={intl.formatMessage({id: 'device.stats.status'})} data={deviceStats?.deviceModeAndShiftData} yField='status'/>
      </Col> */}
    </Row>
  </> :
  <>
    {devicesCount > 0 && <WeatherBox data={weatherData} tvUid={props.tvUid}/>}

    <SpaceBox display={display} tvUid={props.tvUid}/>

    {devicesCount > 0 && <div style={{
      position: 'fixed',
      left: '50%',
      top: '80px',
      transform: 'translate(-50%, 0)',      
    }}>
      <MiddleTopStatus tvUid={props.tvUid} />
    </div>}

    <div style={{
      position: 'fixed',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',      
    }}>
      <PmBigCircle pm25={thePm25} tvUid={props.tvUid}/>
    </div>

    <div style={{
      width: 175,
      height: 750,
      position: 'fixed',
      left: 180,
      top: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      {nodeSummary?.totalWorkTime !== undefined && <NumberCard 
        numberValue={nodeSummary?.totalWorkTime}
        unit={intl.formatMessage({id: 'unit.hour'})}
        label={intl.formatMessage({id: 'label.workTime'})}
        imgUrl={Images.totalWorkTime}
      />}
      {nodeSummary?.totalPurify !== undefined && <NumberCard 
        numberValue={nodeSummary?.totalPurify}
        precision={0}
        unit={intl.formatMessage({id: 'unit.purified'})}
        label={intl.formatMessage({id: 'label.purified'})}
        imgUrl={Images.totalPurify}
      />}
      {nodeSummary?.avgPurify !== undefined && <NumberCard 
        numberValue={nodeSummary?.avgPurify}
        unit={intl.formatMessage({id: 'unit.purifiedAvg'})}
        label={intl.formatMessage({id: 'label.purifiedAvg'})}
        imgUrl={Images.avgPurify}
      />}
      {nodeSummary?.totalPower !== undefined && <NumberCard 
        numberValue={nodeSummary?.totalPower}
        unit={intl.formatMessage({id: 'unit.powerCost'})}
        label={intl.formatMessage({id: 'label.powerCost'})}
        imgUrl={Images.totalPower}
      />}
    </div>

    <div style={{
      width: 300,
      position: 'fixed',
      right: 30,
      top: 100,
    }}>
      {/* {deviceLocations && <MapCard title={intl.formatMessage({id: 'device.locations'}, {count: deviceLocations?.length})} devicesLocations={deviceLocations}/>} */}
      {/* <ChartDeviceType data={deviceTypeData}/> */}
      {/* <div style={{
        marginTop: 20,
      }}>
        <ChartColumn title={intl.formatMessage({id: 'device.stats.status'})} data={deviceStats?.deviceModeAndShiftData} yField='status'/>
      </div> */}
    </div>

    <div style={{
      position: 'fixed',
      left: 400,
      top: '50%',
      transform: 'translate(0, -50%)',
      textAlign: 'center',
    }}>
      <div style={{
        marginBottom: 20,
        fontSize: 22,
      }}>{intl.formatMessage({id: 'text.disinfector'})} X400</div>
      <img src={Images.device('X400')} width={80} height={160}/>
      <div style={{
        marginTop: 10,
        fontSize: 48,
      }}>{deviceStats?.statsDeviceType ? deviceStats?.statsDeviceType['X400'] || 0 : 0}{intl.formatMessage({id: 'text.deviceUnit'})}</div>
      <div style={{
        marginTop: 15,
        fontSize: 16,
      }}>{intl.formatMessage({id: 'text.deployed'})}</div>
    </div>

    <div style={{
      position: 'fixed',
      right: 400,
      top: '50%',
      transform: 'translate(0, -50%)',
      textAlign: 'center',
    }}>
      <div style={{
        marginBottom: 20,
        fontSize: 22,
      }}>{intl.formatMessage({id: 'text.disinfector'})} X1000</div>
      <img src={Images.device('X1000')} width={80} height={160}/>
      <div style={{
        marginTop: 10,
        fontSize: 48,
      }}>{deviceStats?.statsDeviceType ? deviceStats?.statsDeviceType['X1000'] || 0 : 0}{intl.formatMessage({id: 'text.deviceUnit'})}</div>
      <div style={{
        marginTop: 15,
        fontSize: 16,
      }}>{intl.formatMessage({id: 'text.deployed'})}</div>
    </div>

    <div 
      className="qrcode-container"
      style={{
        left: 30,
        bottom: 15, 
        position: 'fixed'
      }}
    >
      <QRCode value={props.tvUid} includeMargin size={80}/>
    </div>
  </>);
};


export default NodePage;