import React from 'react';
import QRCode from 'qrcode.react';
import { isPortraitScreen } from '@/hooks/tvClient';
import { Images } from '@/hooks/images';

const HomePage: React.FC = (props) => {

  const tvUid = props.match.params.tvUid;
  const isPortrait = isPortraitScreen();

  return (<>
  {
    isPortrait ? <>
      <video playsInline autoPlay muted loop id="bgvid" poster={Images.portraitSetupVideoPoster}>
        <source src={Images.portraitSetupVideo} type="video/mp4" />
      </video>
      {/* <img src={Images.portraitBackgroundVideoPoster} width="100%" height="100%" style={{position: 'fixed'}}/> */}
      <div style={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
      }}
        className="over-video"
      >
        <div style={{
          fontSize: 55,
          margin: '260px auto 0 auto',
          width: 800,
        }}>
          欢迎使用<br/>
          Welcome to use<br/>
          720玄武2000大屏可视化应用<br/>
          720 Xuanwu Visualizaiton
        </div>
        <div style={{
          fontSize: 32,
          marginTop: 70,
          lineHeight: 2,
        }}>
          请打开手机上的“玄武运维”APP
          <br/>
          Please open your 'Xuanwu OpTool' APP<br/>
          点击“设置大屏显示”<br/>
          click 'Setup the TV Client'<br/>
          扫描屏幕中的二维码进行后续操作<br/>
          Scan QR code and follow the instruction
        </div>
        <div className="qrcode-container" style={{
          marginTop: 100,
        }}>
          <QRCode value={tvUid} includeMargin size={150}/>
        </div>
      </div>
    </> : <>
      <video playsInline autoPlay muted loop id="bgvid" poster={Images.setupVideoPoster}>
        <source src={Images.setupVideo} type="video/mp4" />
      </video>
      <div style={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
      }}
        className="over-video"
      >
        <div style={{
          fontSize: 60,
          marginTop: 130,
        }}>
          欢迎使用 720 玄武2000大屏可视化应用<br/>
          Welcome to use 720 Xuanwu Visualizaiton
        </div>
        <div style={{
          fontSize: 38,
          marginTop: 30,
        }}>
          请打开手机上的“玄武运维”APP | 点击“设置大屏显示” | 扫描屏幕中的二维码进行后续操作<br/>
          Please open your 'Xuanwu OpTool' APP | Click 'Setup the TV Client' | Scan QR code and follow the instruction
        </div>
        <div className="qrcode-container" style={{
          marginTop: 80,
        }}>
          <QRCode value={tvUid} includeMargin size={150}/>
        </div>
      </div>
    </>
  }
  </>);
};


export default HomePage;