import { useDebugPm, useIntl, useTvClientDisplay, useTvClientDisplayDeviceStatus, useTvClientDisplayDeviceWeather } from '@/hooks/tvClient';
import React from 'react';
import QRCode from 'qrcode.react';
import BarWeatherBox from './components/BarWeatherBox';
import BarStatus from './components/BarStatus';
import BarStatItem from './components/BarStatItem';
import BarPmBox from './components/BarPmBox';
import { Images } from '@/hooks/images';

interface BarDevicePageProps {
  tvUid: string;
}

const BarDevicePage: React.FC<BarDevicePageProps> = (props) => {
  const {data: display} = useTvClientDisplay(props.tvUid);
  const {data: deviceStatus} = useTvClientDisplayDeviceStatus(props.tvUid);
  const {data: weatherData} = useTvClientDisplayDeviceWeather(props.tvUid);
  const intl = useIntl(props.tvUid);

  let thePm25: number | undefined = undefined;

  console.log('useDebugPm');
  if (display?.displayStyle.startsWith('Debug') && deviceStatus && weatherData) {
    console.log(weatherData.airPm25, deviceStatus.pm25);
    thePm25 = useDebugPm(deviceStatus.pm25, weatherData.airPm25);
  } else {
    thePm25 = deviceStatus?.pm25;
  }

  const deviceSwitchWidth = 270;
  const switchIconSize = 60;
  const switchFontSize = 30;

  const anion = deviceStatus?.online ? (deviceStatus?.powerOn ? deviceStatus?.anionOn : false) : undefined;
  const uv = deviceStatus?.online ? (deviceStatus?.powerOn ? deviceStatus?.uvOn : false) : undefined;
  const modeIndex = deviceStatus?.online ? (deviceStatus?.powerOn ? deviceStatus?.mode : -1) : -2;
  const Modes: any = {
    [-2]: {
      icon: 'off',
      text: '--',
    },
    [-1]: {
      icon: 'off',
      text: intl.formatMessage({id: 'text.powerOff'}),
    },
    [0]: {
      icon: 'speed_on',
      text: intl.formatMessage({id: 'text.manual'}),
    },
    [1]: {
      icon: 'auto_on',
      text: intl.formatMessage({id: 'text.auto'}),
    },
    [2]: {
      icon: 'sleep_on',
      text: intl.formatMessage({id: 'text.sleep'}),
    },
  };
  const mode = modeIndex !== undefined ? Modes[modeIndex] : undefined;
  const shift = deviceStatus?.shift;

  const scene = deviceStatus?.online ? 
    (deviceStatus?.applyScene ? 
      (deviceStatus?.sceneInterruptWaitSeconds !== undefined
         && deviceStatus?.sceneInterruptWaitSeconds > 0 ? 
           'pause'
         : 'apply')
       : 'stop')
     : undefined;

  return (<>
    <div style={{
      height: 240,
      width: '100%',
      position: 'relative',
      backgroundColor: 'rgba(0,0,0,0.5)',
    }}>
      <BarWeatherBox data={weatherData} display={display} tvUid={props.tvUid}/>
      <BarStatus deviceStatus={deviceStatus} tvUid={props.tvUid}/>

      <div style={{
        position: 'absolute',
        left: 380,
        width: 680,
        top: 120,
        transform: 'translate(0, -50%)',
      }}>
        <div style={{
          fontSize: 42,
          textAlign: 'center',
        }}>
          720 {display?.deviceTypeShort} <span style={{fontSize: '0.8em'}}>(SN: {display?.displayPath[0]})</span> {/*intl.formatMessage({id: 'text.disinfector'})*/}
        </div>
        <div style={{
          textAlign: 'center',
        }}>
          <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
            <BarPmBox pm25={weatherData?.airPm25} label={intl.formatMessage({id: 'text.outdoor'})} tvUid={props.tvUid}/>
          </div>
          <div style={{
            display: 'inline-block',
            height: 120,
            verticalAlign: 'middle',
            width: 3,
            margin: '0 20px',
            backgroundColor: 'white',
          }}></div>
          <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
            <BarPmBox pm25={thePm25} label={intl.formatMessage({id: 'text.indoor'})} tvUid={props.tvUid}/>
          </div>
        </div>
      </div>

      <div style={{
        position: 'absolute',
        top: 120,
        left: 'calc(50% - 130px)',
        transform: 'translate(0,-50%)',
      }}>
        <div>
          {mode && shift !== undefined && <div style={{
            width: deviceSwitchWidth,
            display: 'inline-block',
          }}>
            <img src={Images.modeIcon(mode.icon)} width={switchIconSize} height={switchIconSize}/>
            <div style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: 5,
              fontSize: switchFontSize,
            }}>{mode.text}{deviceStatus?.powerOn ? ` | ${intl.formatMessage({id: 'text.shift'}, {shift: shift+1})}` : ''}</div>
          </div>}
          <div style={{
            width: deviceSwitchWidth,
            display: 'inline-block',
          }}>
            <img src={Images.anion(anion)} width={switchIconSize} height={switchIconSize}/>
            <div style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: 5,
              fontSize: switchFontSize,
            }}>{anion === true ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (anion === false ? `${intl.formatMessage({id: 'device.function.anionOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
          </div>
        </div>

      <div style={{ marginTop: 20 }}>
        <div style={{
          width: deviceSwitchWidth,
          display: 'inline-block',
        }}>
          <img src={Images.uv(uv)} width={switchIconSize} height={switchIconSize}/>
          <div style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginLeft: 5,
            fontSize: switchFontSize,
          }}>{uv === true ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.on'})}` : (uv === false ? `${intl.formatMessage({id: 'device.function.uvOn'})} | ${intl.formatMessage({id: 'device.list.off'})}` : '--')}</div>
        </div>
        <div style={{
          width: deviceSwitchWidth,
          display: 'inline-block',
        }}>
          <img src={Images.scene(scene === 'apply')} width={switchIconSize} height={switchIconSize}/>
          <div style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginLeft: 5,
            fontSize: switchFontSize,
          }}>{scene === undefined ? '--' : `${intl.formatMessage({id: 'device.list.scene'})} | ${scene === 'apply' ? intl.formatMessage({id: 'scene.stats.apply'}) : (scene === 'pause' ? intl.formatMessage({id: 'scene.stats.pause'}) : intl.formatMessage({id: 'scene.stats.stop'}))}`}</div>
        </div>
      </div>
    </div>

      <div style={{
        width: 620,
        position: 'absolute',
        right: 100,
        top: 120,
        transform: 'translate(0, -50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <div>
          {deviceStatus?.totalPurified !== undefined && <BarStatItem 
            numberValue={deviceStatus?.totalPurified}
            precision={0}
            unit={intl.formatMessage({id: 'unit.purified'})}
            label={intl.formatMessage({id: 'label.purified'})}
          />}
          {deviceStatus?.totalPowerCost !== undefined && <BarStatItem 
            numberValue={deviceStatus?.totalPowerCost / 1000}
            unit={intl.formatMessage({id: 'unit.powerCost'})}
            label={intl.formatMessage({id: 'label.powerCost'})}
          />}
        </div>
        <div style={{ marginTop: 25 }}>
          {deviceStatus?.temperature !== undefined && <BarStatItem 
            numberValue={deviceStatus?.temperature}
            precision={0}
            unit="℃"
            label={intl.formatMessage({id: 'temperature.indoor'})}
          />}
          {deviceStatus?.humidity !== undefined && <BarStatItem 
            numberValue={deviceStatus?.humidity}
            precision={0}
            unit="%"
            label={intl.formatMessage({id: 'humidity.indoor'})}
          />}
        </div>
      </div>

      <div 
        className="qrcode-container"
        style={{
          right: 30,
          top: 70, 
          position: 'absolute'
        }}
      >
        <QRCode value={props.tvUid} includeMargin size={100}/>
      </div>
    </div>
  </>);
};


export default BarDevicePage;