export default {
  'config.tv-client.addNewTvClient': '新增大屏顯示',
  'config.tv-client.modifyTvClient': '修改大屏顯示',
  'config.tv-client.deleteTvClient': '刪除大屏顯示',
  'config.tv-client.deleteTvClient.confirm': '刪除大屏顯示“{tvClientName}”，確認嗎？',
  'config.tv-client.tvClientUid': '大屏用戶端UID',
  'config.tv-client.tvClientUid.require': '請輸入大屏用戶端UID！',
  'config.tv-client.online': '線上',
  'config.tv-client.tvClientName': '大屏名稱',
  'config.tv-client.tvClientName.require': '請輸入大屏名稱！',
  'config.tv-client.displayType': '顯示類型',
  'config.tv-client.displayType.Device': '設備',
  'config.tv-client.displayType.Node': '空間',
  'config.tv-client.displayItem': '顯示內容',
  'config.tv-client.displayStyle': '顯示風格',
  'config.tv-client.displayStyle.require': '請輸入顯示風格！',
  'config.tv-client.displayStyle.FullScreen': '全屏',
  'config.tv-client.displayStyle.Top': '螢幕頂部',
  'config.tv-client.displayStyle.Bottom': '螢幕底部',
  'config.ruleset.deviceRuleset': '規則集',
  'config.ruleset.addNewRule': '新增規則',
  'config.ruleset.deleteRule': '刪除',
  'config.ruleset.ruleOrder': '執行順序',
  'config.ruleset.x400Condition': 'X400條件',
  'config.ruleset.x1000Condition': 'X1000條件',
  'config.ruleset.x400Command': 'X400指令',
  'config.ruleset.x1000Command': 'X1000指令',

  'operation.tab.summary': '統計概要',
  'operation.tab.devices': '設備清單',

  'device.function.powerOn': '機器開關',
  'device.function.modeAndShift': '風速檔位',
  'device.function.lockOn': '童鎖',
  'device.function.uvOn': '紫外線',
  'device.function.anionOn': '等離子',
  'device.function.ledOff': '熄屏',
  'device.function.beepOn': '按鍵音',
  'device.function.colorLightMode': '氛圍燈',

  'device.function.modeAndShift.1': '睡眠檔',
  'device.function.modeAndShift.2': '自動檔',
  'device.function.modeAndShift.3': '風速1檔',
  'device.function.modeAndShift.4': '風速2檔',
  'device.function.modeAndShift.5': '風速3檔',
  'device.function.modeAndShift.6': '風速4檔',
  'device.function.modeAndShift.7': '風速5檔',
  'device.function.modeAndShift.8': '風速6檔',

  'device.mode.offline': '離線',
  'device.mode.powerOff': '關機',
  'device.mode.noPowerSupply': '電源斷開',
  'device.mode.Mode_0_Shift_0': '手動1檔',
  'device.mode.Mode_0_Shift_1': '手動2檔',
  'device.mode.Mode_0_Shift_2': '手動3檔',
  'device.mode.Mode_0_Shift_3': '手動4檔',
  'device.mode.Mode_0_Shift_4': '手動5檔',
  'device.mode.Mode_0_Shift_5': '手動6檔',
  'device.mode.Mode_1_Shift_0': '自動1檔',
  'device.mode.Mode_1_Shift_1': '自動2檔',
  'device.mode.Mode_1_Shift_2': '自動3檔',
  'device.mode.Mode_1_Shift_3': '自動4檔',
  'device.mode.Mode_1_Shift_4': '自動5檔',
  'device.mode.Mode_1_Shift_5': '自動6檔',
  'device.mode.Mode_2_Shift_0': '睡眠1檔',

  'device.alert.offline': '離線',
  'device.alert.noPowerSupply': '電源斷開',
  'device.alert.filterUsedUp': '濾芯告警',
  'device.alert.doorOpen': '艙門蓋未關',
  'device.alert.filterElementPackageNotRemoved': '濾芯保護膜未拆',

  'device.command.status.offline': '設備離線',
  'device.command.status.jam': '指令被阻塞',
  'device.command.status.sent': '指令已發送',
  'device.command.status.received': '指令已被接收',
  'device.command.status.timeout': '指令超時',
  'device.command.status.set': '已設置',

  'device.function.restoreAfterHours': '小時後恢復智慧場景/規則',
  'device.function.concurrency': '併發數',
  'device.function.ruleSet': '設置智慧規則',
  'device.scene.apply_device_scene': '是否啟用場景',
  'device.scene.device_scene_id': '選擇智能場景',

  'isoCountryCode.CHN': '中華人民共和國',
  'isoCountryCode.MYS': 'Malaysia',

  'appclient.error.needLocationPermission': '需要定位許可權，請重試',

  'app.copyright': '2020-2070 版權所有 柒貳零（北京）健康科技有限公司',
  'device.list.deviceSN': '設備SN',
  'device.list.spacePath': '空間路徑',
  'device.list.category': '分類',
  'device.list.deviceType': '設備型號',
  'device.list.status': '運行狀態',
  'device.list.alert': '異常告警',
  'device.list.filterLife': '濾芯剩餘',
  'device.list.scene': '智能場景',
  'device.list.scenePause': '場景暫停',
  'device.list.deviceCount': '設備數：',
  'device.list.batchSetScene': '批量設置場景',
  'device.list.batchCommand': '批量立即指令',
  'filterLife.stats.now': '立即更換',
  'filterLife.stats.days30': '30天內更換',
  'filterLife.stats.days60': '60天內更換',
  'filterLife.stats.noNeed': '無需更換',
  'scene.stats.notSet': '空',
  'scene.stats.apply': '啟用',
  'scene.stats.pause': '暫停',
  'scene.stats.stop': '停用',
  'device.locations': '設備分佈（共{count}台）',
  'device.types': '設備型號統計',
  'device.alerts': '設備故障告警',
  'device.stats.status': '設備運行模式統計',
  'device.stats.X1000FilterLife': 'X1000濾芯剩餘統計',
  'device.stats.X400FilterLife': 'X400濾芯剩餘統計',
  'device.stats.scene': '設備智慧場景統計',
  'device.list.onlyAllowSameType': '請只選擇同一種型號的設備',
  'device.list.cannotAcrossOrg': '不能跨組織批量設置場景',
  'device.list.noChange': '不變',
  'device.list.on': '開',
  'device.list.off': '關',
  'device.list.unknown': '未知',
  'colorLightMode.0': '關',
  'colorLightMode.1': '自動',
  'colorLightMode.2': '其它',
  'colorLightMode.3': '藍色',
  'colorLightMode.4': '紫色',
  'colorLightMode.5': '綠色',
  'colorLightMode.6': '黃色',
  'colorLightMode.7': '紅色',
  'pm25.gauge.fine': '優',
  'pm25.gauge.good': '良',
  'pm25.gauge.poor': '差',
  'pm25.indoor': '室內PM2.5',
  'pm25.outdoor': '室外PM2.5',
  'temperature.indoor': '室內溫度',
  'temperature.gauge.cold': '冷',
  'temperature.gauge.comfort': '舒適',
  'temperature.gauge.hot': '熱',
  'humidity.indoor': '室內濕度',
  'humidity.gauge.dry': '乾燥',
  'humidity.gauge.littleDry': '略幹',
  'humidity.gauge.comfort': '舒適',
  'unit.hour': '小時',
  'label.workTime': '累計工作',
  'unit.purified': '米³',
  'label.purified': '累計消毒淨化',
  'label.purifiedAvg': '平均消毒淨化',
  'unit.purifiedAvg': '米³/台·小時',
  'label.powerCost': '累計耗電',
  'unit.powerCost': '度',
  'label.powerCostAvg': '平均耗電',
  'unit.powerCostAvg': '度/台·小時',
  'period.month': '月',
  'period.week': '周',
  'period.day': '日',
  'chart.purify': '淨化數據',
  'chart.powerCost': '能耗運行資料',
  'dlg.noChange': '不改變',
  'dlg.noNeedToChange': '沒有需要改變的設置',
  'dlg.noNeedToSendCommand': '沒有指令需要發送',
  'dlg.notSetCommand': '沒有設置任何指令',
  'dlg.restoreAfterHours.required': '請輸入“小時後恢復智慧場景/規則”',
  'dlg.concurrency.required': '請輸入併發數',
  'dlg.prev': '上一步',
  'dlg.next': '下一步',
  'dlg.cancel': '取消',
  'dlg.finish': '完成',
  'dlg.table.seq': '順序',
  'dlg.table.sn': '設備SN',
  'dlg.table.commandStatus': '指令發送狀態',
  'dlg.table.count': '總數：{count}',
  'dlgDeviceScene.title': '設置設備的智慧場景',
  'dlgDeviceScene.step1': '將要設置場景的設備清單',
  'dlgDeviceScene.step2': '設置場景',
  'dlgDeviceScene.step3': '場景設置過程',
  'dlgDeviceCommand.title': '向設備發送立即指令',
  'dlgDeviceCommand.step1': '將要發送指令的設備清單',
  'dlgDeviceCommand.step2': '配置將要發送的指令',
  'dlgDeviceCommand.step3': '指令發送過程',
  'device.location': '設備位置',
  'device.setScene': '設置場景',
  'device.sendCommand': '發送指令',
  'device.card.status': '設備運行狀態',
  'device.card.alerts': '設備告警',
  'device.card.mode': '模式',
  'device.card.mode.manual': '手動',
  'device.card.mode.auto': '自動',
  'device.card.mode.sleep': '睡眠',
  'device.card.speed': '風速',
  'device.card.shift': '{shift}檔',
  'device.card.basicInfo': '設備基本資訊',
  'device.card.refresh': '刷新',
  'device.card.firmwareVersion': '固件版本',
  'device.card.remain': '剩餘',
  'device.card.currentRuleset': '當前規則集',
  'device.card.noRuleset': '無',
  'ruleset.empty': '清空',
  'ruleset.custom': '自訂',
  'ruleset.moveUp': '上移',
  'ruleset.humanActivity': '附近人體活動',
  'ruleset.lightNearby': '附近環境明亮',
  'ruleset.powerOn': '開機',
  'ruleset.powerOff': '關機',
  'ruleset.modeSetTo': '設為{mode}',
  'ruleset.colorLightModeSetTo': '氛圍燈設置為 {mode}',
  'app.yes': '是',
  'app.no': '否',
  'scene.apply_on_workday': '工作日',
  'scene.apply_on_holiday': '非工作日',
  'scene.apply_on_monday': '週一',
  'scene.apply_on_tuesday': '週二',
  'scene.apply_on_wednesday': '週三',
  'scene.apply_on_thursday': '週四',
  'scene.apply_on_friday': '週五',
  'scene.apply_on_saturday': '週六',
  'scene.apply_on_sunday': '周日',
  'scene.by_workday_dayoff': '按工作日/非工作日',
  'scene.by_weekday': '按周幾',
  'scene.date_condition_required': '需要選擇至少一種日期條件',
  'scene.command_ruleset_required': '沒有設置任何指令或規則集',
  'scene.noChangeRuleset': '不改變 規則集',
  'scene.emptyRuleset': '清空 規則集',
  'scene.customRuleset': '自訂 規則集',
  'xuanwuApi.error.cannotDeleteNotEmptySpace': '無法刪除非空的空間結點',
  'xuanwuApi.error.cannotDeleteNotEmptyOrg': '無法刪除非空的組織結點',
  'opclient.xuanwuOperation': '玄武運維',
  'opclient.logout': '登出',
  'opclient.installPuriX': '安裝X系列空氣消毒機',
  'opclient.testPuriX': '測試X系列空氣消毒機',
  'opclient.setupTvClient': '設置大屏顯示',
  'opclient.accessDeny': '沒有足夠的許可權',
  'opclient.notPuriX': '不是X系列消毒機的SN號',
  'opclient.setupWifiFinished': '已完成綁定和配網',
  'opclient.setupWifiCancelled': '已完成綁定，配網被取消',
  'opclient.clickToSelectSpace': '請點擊選擇空間',
  'opclient.bindSpace': '關聯的空間',
  'opclient.deviceSN': '設備SN號',
  'opclient.clickToBind': '請點擊進行掃碼和定位',
  'opclient.bindFinish': '已完成綁定',
  'opclient.bindDevice': '綁定設備',
  'opclient.andSetupWifi': '並配網',
  'opclient.notQrCodeForTvSetup': '不是大屏顯示的設置二維碼',
  'opclient.onlySpaceNodeAllowed': '只能選擇空間結點',
  'opclient.cannotBindToSpaceNodeWithChildren': '不能綁定到有子空間的結點',
  'opclient.selectSpace': '選擇空間',
  'opclient.sendCommand': '發送{deviceTypeShort}指令',
  'opclient.clickToScan': '請點擊進行掃碼',
  'opclient.powerSupply': '有電源',
  'opclient.lastManualCmd': '手動指令',
  'opclient.lastManualTime': '手動時間',
  'opclient.onlineCause': '上線原因',
  'opclient.macAddress': 'MAC地址',
  'opclient.ipAddress': 'IP地址',
  'opclient.oidVersion': 'oid版本',
  'opclient.totalAbsorbed': '累計吸附',
  'opclient.debugInfo': '調試信息',
  'opclient.filterElementPackage': '濾芯包裝袋',
  'opclient.notRemoved': '未拆除',
  'opclient.removed': '已拆除',
  'opclient.genuineFilterElement': '原版濾芯',
  'opclient.notDetected': '未檢測到',
  'opclient.detected': '檢測到',
  'opclient.displayContent.required': '請選擇顯示內容！',
  'opclient.selectDisplayContent': '選擇顯示內容',
  'opclient.orgNode.required': '請選擇所屬組織！',
  'opclient.onlyAllowOrgNodeWithoutChildren': '只能選擇末端組織',
  'opclient.selectOrgNode': '選擇所屬組織',
  'opclient.displaySettings': '大屏顯示設定',
  'opclient.orgNode': '所屬組織',
  'opclient.displayName': '請輸入大屏名稱',
  'opclient.selectOrgNodeFirst': '請先選擇所屬組織',
  'opclient.success': '操作成功',
  'opclient.deleted': '已刪除',


  'config.tv-client.displayStyle.DebugFullScreen': 'Debug全屏',
  'config.tv-client.displayStyle.DebugTop': 'Debug螢幕頂部',
  'config.tv-client.displayStyle.DebugBottom': 'Debug螢幕底部',
  'config.tv-client.displayLocale': '本土化',
  'config.tv-client.displayLocale.require': '請選擇本土化！',
  'opclient.selectDisplayLocale': '選擇本土化',

  'locale.zh-CN': '简体中文',
  'locale.en-US': '英文',
  'locale.zh-TW': '繁体中文',

  'unit.pm25': 'μg / m³',
  'text.indoor': '室內',
  'text.outdoor': '室外',
  'level.pm25.优': '優',
  'level.pm25.良': '良',
  'level.pm25.轻度污染': '輕度污染',
  'level.pm25.中度污染': '中度污染',
  'level.pm25.重度污染': '重度污染',
  'level.pm25.严重污染': '嚴重污染',
  'format.date.moment': 'YYYY年MM月DD日 HH:mm',
  'text.disinfecting': '消毒淨化中',
  'text.deviceStandby': '消毒淨化待機中',
  'text.deviceOffline': '消毒機離線了',
  'text.filterRemain': '濾芯剩餘',
  'text.powerOff': '已關機',
  'text.manual': '手動',
  'text.auto': '自動',
  'text.sleep': '睡眠',
  'text.shift': '{shift}檔',
  'text.disinfector': '空氣消毒機',
  'text.deployed': '已完成部署',
  'text.deviceUnit': '台',
  'level.temperature.寒冷': '寒冷',
  'level.temperature.冷': '冷',
  'level.temperature.舒适': '舒適',
  'level.temperature.热': '熱',
  'level.temperature.酷热': '酷熱',
  'level.humidity.干燥': '乾燥',
  'level.humidity.略干': '略乾',
  'level.humidity.舒适': '舒適',
  'level.humidity.潮湿': '潮濕',

};
