export const Images = {
  totalPurify: 'https://share1.720health.com/ic_totalpurify.gif',
  weiBangDing: 'https://share1.720health.com/ic_weibangding.gif',
  daiji: 'https://share1.720health.com/ic_daiji.gif',
  totalPower: 'https://share1.720health.com/ic_totalpower.gif',
  lixian: 'https://share1.720health.com/ic_lixian.gif',
  titleClean: 'https://share1.720health.com/ic_title_clean.gif',
  avgPurify: 'https://share1.720health.com/ic_avgpurify.gif',
  avgPower: 'https://share1.720health.com/ic_avgpower.gif',
  totalWorkTime: 'https://share1.720health.com/ic_totalworktime.gif',
  setupVideoPoster: 'https://share1.720health.com/TVBS.png',
  setupVideo: 'https://share1.720health.com/XTVB.mp4',
  portraitSetupVideoPoster: 'https://share1.720health.com/xuanwu_bg_V.jpg',
  portraitSetupVideo: 'https://share1.720health.com/TVB_V2.mp4',
  backgroundVideoPoster: 'https://share1.720health.com/TVB.png',
  backgroundVideo: 'https://share1.720health.com/TVB2.mp4',
  portraitBackgroundVideoPoster: 'https://share1.720health.com/xuanwu_bg_V.jpg',
  portraitBackgroundVideo: 'https://share1.720health.com/TVB_V2.mp4',
  modeIcon: (icon: string) => `/img/ic_${icon}.png`,
  anion: (on: boolean) => `/img/ic_denglizi${on === true ? '_on' : ''}.png`,
  uv: (on: boolean) => `/img/ic_uv${on === true ? '_on' : ''}.png`,
  scene: (apply: boolean) => `/img/ic_ai${apply ? '_on' : ''}.png`,
  device: (deviceTypeShort: string) => `https://share1.720health.com/pic_${deviceTypeShort}N-3.png`,
  // device: (deviceTypeShort: string) => `/img/pic_${deviceTypeShort}N.png`,
}
