import { getPm25Level, useIntl } from '@/hooks/tvClient';
import React from 'react';

interface PmBigCircleProps {
  pm25?: number;
  tvUid: string;
}

const PmBigCircle: React.FC<PmBigCircleProps> = ({pm25, tvUid}) => {
  const level: any = pm25 ? getPm25Level(pm25) : {};
  const intl = useIntl(tvUid);
  return (<>
    {pm25 !== undefined && <div style={{
      height: 400,
      width: 400,
      borderRadius: 300,
      border: 'solid 15px',
      borderColor: level.Color,
      textAlign: 'center',
      lineHeight: 1,
      paddingTop: 60,
    }}>
      <div style={{fontSize: '24px'}}>{intl.formatMessage({id: 'text.indoor'})} | PM2.5</div>
      <div style={{fontSize: '42px', marginTop: 30}}>{intl.formatMessage({id: `level.pm25.${level.Level}`})}</div>
      <div style={{fontSize: '66px', marginTop: 10}}>{pm25}</div>
      <div style={{fontSize: '18px', marginTop: 30}}>{intl.formatMessage({id: 'unit.pm25'})}</div>
    </div>}
  </>);
}

export default PmBigCircle;