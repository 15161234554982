import { Images } from '@/hooks/images';
import React from 'react';

const HomePage: React.FC = (props) => {

  const deviceSwitchWidth = 100;

  return (<>
  <img src={Images.portraitBackgroundVideoPoster} width="100%" height="100%"/>
  </>);
};


export default HomePage;