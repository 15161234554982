import React, { useEffect } from 'react';
import BarDevicePage from './BarDevicePage';
import BarNodePage from './BarNodePage';

interface BarDisplayPageProps {
  tvUid: string;
  display?: any;
}

const BarDisplayPage: React.FC<BarDisplayPageProps> = ({display, tvUid}) => {

  // const tvUid = props.match.params.tvUid;
  // const {data: display} = useTvClientDisplay(tvUid);

  document.body.style.zoom = 0.4;
  // document.body.style.transform = `scale(${1/window.devicePixelRatio})`;

  // console.log(display);

  return (<>
    <div>
      {display?.displayType === 'Node' && <BarNodePage tvUid={tvUid}/>}
      {display?.displayType === 'Device' && <BarDevicePage tvUid={tvUid}/>}
    </div>
  </>);
};


export default BarDisplayPage;