import { Card } from "antd";
import React from "react";
import { Coordinate, Annotation, Chart, Axis, Point } from "bizcharts";

interface FilterLifeCircleProps {
  percentValue?: number;
  label: string;
}

const FilterLifeCircle: React.FC<FilterLifeCircleProps> = (props) => {
  const data = [{ value: props.percentValue ?? 0 }];

  const color = props.percentValue !== undefined ? (
    props.percentValue > 30 ? 'rgb(54,58,253)' : 'rgb(244,59,66)'
  ) : 'black';

  return (
    <Card
      bordered={false}
      style={{ textAlign: 'center', minWidth: 175, backgroundColor: 'rgba(0,0,0,0)'}}
      bodyStyle={{padding: '0px 0px 8px 0px', position: 'relative'}}
    >
      <Chart
        height={150}
        data={data}
        padding={[0, 0, 0, 0]}
        scale={{
          value: {
            type: 'linear',
            min: 0,
            max: 100.1,
          }
        }}
        autoFit
      >
        <Coordinate
          type="polar"
          radius={0.75}
          startAngle={(-1/2) * Math.PI}
          endAngle={(3/2) * Math.PI}
        />
        <Axis
          name="value"
          line={null}
          tickLine={null}
          label={null}
          grid={null}
        />
        <Point
          position="value*1"
          size={0}
        />
        <Annotation.Arc
          start={[0, 1]}
          end={[100, 1]}
          animate={false}
          style={{
            stroke: 'rgba(70,70,70,0.7)',//'#24242C',
            lineWidth: 8,
            lineDash: null,
          }}
        />
        {
          props.percentValue !== undefined && <Annotation.Arc
            start={[0, 1]}
            end={[props.percentValue, 1]}
            style={{
              stroke: color,
              lineWidth: 8,
              lineDash: null,
              lineCap: 'round',
            }}
            animate={false}
          />
        }
        <Annotation.Text
          position={['50%', '80%']}
          content={`${props.percentValue}%`}
          style={{
            fontSize: 20,
            stroke: null,
            fill: 'white',
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            textAlign: 'center',
          }}
          offsetY={-60}
          animate={false}
        />
        <Annotation.Text
          position={['50%', '80%']}
          content={props.label}
          style={{
            fontSize: 17,
            stroke: null,
            fill: 'white',
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            textAlign: 'center',
          }}
          offsetY={-30}
          animate={false}
        />
      </Chart>
    </Card>
  );
}

export default FilterLifeCircle;