import { Card } from "antd";
import React from "react";
import { Coordinate, Annotation, Chart, Axis, Point } from "bizcharts";
import FlipNumbers from "react-flip-numbers";

interface GaugeCardProps {
  gaugeValue?: number;
  colors: string[];
  levels: any;
  gaugeUnit: string;
  gaugeLabel: string;
  maxValue: number;
}

const GaugeCard: React.FC<GaugeCardProps> = (props) => {
  const data = [{ value: props.gaugeValue ?? 0 }];
  const ticks = Object.keys(props.levels).map(l => Number(l)).sort((a, b) => a - b);
  let currentLevel = '';
  for (const key in props.levels) {
    if (Object.prototype.hasOwnProperty.call(props.levels, key)) {
      const element = props.levels[key];
      if (props.gaugeValue ?? 0 > Number(key)) {
        currentLevel = element;
      }
    }
  } 

  return (
    <Card
      bordered={false}
      style={{ textAlign: 'center', minWidth: 175, backgroundColor: 'rgba(0,0,0,0)'}}
      bodyStyle={{padding: '0px 0px 8px 0px', position: 'relative'}}
    >
      <Chart
        height={170}
        data={data}
        padding={[0, 0, 0, 0]}
        scale={{
          value: {
            type: 'linear',
            min: 0,
            max: props.maxValue,
            ticks: ticks,
            formatter: (v) => props.levels[v],
          }
        }}
        autoFit
      >
        <Coordinate
          type="polar"
          radius={0.75}
          startAngle={(-10 / 8) * Math.PI}
          endAngle={(2 / 8) * Math.PI}
        />
        <Axis
          name="value"
          line={null}
          label={{
            offset: -21,
            style: {
              fontSize: 12,
              fill: 'rgba(0,0,0,0)',
              textAlign: 'center',
              textBaseline: 'middle',
            },
          }}
          tickLine={{
            style: {
              stroke: 'white',
              lineWidth: 2,
            },
            length: -9,
          }}
          grid={null}
        />
        <Point
          position="value*1"
          size={0}
        />
        <Annotation.Arc
          start={[0, 1]}
          end={[props.maxValue, 1]}
          animate={false}
          style={{
            stroke: '#CBCBCB',
            lineWidth: 8,
            lineDash: null,
          }}
        />
        {
          data[0].value < ticks[0] && <Annotation.Arc
            start={[0, 1]}
            end={[data[0].value, 1]}
            style={{
              stroke: props.colors[0],
              lineWidth: 8,
              lineDash: null,
            }}
            animate={false}
          />
        }
        {
          data[0].value >= ticks[0] && <Annotation.Arc
            start={[0, 1]}
            end={[ticks[0], 1]}
            style={{
              stroke: props.colors[0],
              lineWidth: 8,
              lineDash: null,
            }}
            animate={false}
          />
        }
        {
          data[0].value > ticks[0] && data[0].value < ticks[1] && <Annotation.Arc
            start={[ticks[0], 1]}
            end={[data[0].value, 1]}
            style={{
              stroke: props.colors[1],
              lineWidth: 8,
              lineDash: null,
            }}
            animate={false}
          />
        }
        {
          data[0].value >= ticks[1] && <Annotation.Arc
            start={[ticks[0], 1]}
            end={[ticks[1], 1]}
            style={{
              stroke: props.colors[1],
              lineWidth: 8,
              lineDash: null,
            }}
            animate={false}
          />
        }
        {
          data[0].value > ticks[1] && data[0].value < ticks[2] && <Annotation.Arc
            start={[ticks[1], 1]}
            end={[data[0].value, 1]}
            style={{
              stroke: props.colors[2],
              lineWidth: 8,
              lineDash: null,
            }}
            animate={false}
          />
        }
        {
          data[0].value >= ticks[2] && <Annotation.Arc
            start={[ticks[1], 1]}
            end={[ticks[2], 1]}
            style={{
              stroke: props.colors[2],
              lineWidth: 8,
              lineDash: null,
            }}
            animate={false}
          />
        }
        {
          data[0].value > ticks[2] && <Annotation.Arc
            start={[ticks[2], 1]}
            end={[data[0].value > props.maxValue ? props.maxValue : data[0].value, 1]}
            style={{
              stroke: props.colors[3],
              lineWidth: 8,
              lineDash: null,
            }}
            animate={false}
          />
        }
        <Annotation.Text
          position={['50%', '80%']}
          content={props.gaugeLabel}
          style={{
            fontSize: 17,
            stroke: null,
            fill: 'white',
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            textAlign: 'center',
          }}
          offsetY={25}
          animate={false}
        />
        <Annotation.Text
          position={['50%', '80%']}
          content={currentLevel}
          style={{
            fontSize: 14,
            stroke: null,
            fill: 'white',
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            textAlign: 'center',
          }}
          offsetY={-65}
          animate={false}
        />
      </Chart>
      <div style={{position:'absolute', top: '50%', width: '100%'}}>
        <FlipNumbers
          height={28}
          width={18}
          color="white"
          play
          duration={2}
          numbers={props.gaugeValue ? String(props.gaugeValue) : ''}
        />
        {props.gaugeUnit && <div style={{
          position:'absolute', 
          top: '45%',
          left: '60%',
          fontSize: '1em',
          fontWeight: 'bold',
        }}>
          {props.gaugeUnit}
        </div>}
      </div>
    </Card>
  );
}

export default GaugeCard;