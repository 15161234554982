import { Images } from '@/hooks/images';
import { isPortraitScreen, useTvClientDisplay } from '@/hooks/tvClient';
import React, { useEffect } from 'react';
import BarDisplayPage from './BarDisplayPage';
import DevicePage from './DevicePage';
import NodePage from './NodePage';

interface FullScreenProps {
  display?: any;
  tvUid?: string;
}

const FullScreen: React.FC<FullScreenProps> = ({display, tvUid}) => {
  return (
  isPortraitScreen() ? <>
    {
      (display?.displayStyle === 'FullScreen' || display?.displayStyle === 'DebugFullScreen') ? 
      <video playsInline autoPlay muted loop id="bgvid" poster={Images.portraitBackgroundVideoPoster}>
        <source src={Images.portraitBackgroundVideo} type="video/mp4" />
      </video> 
      : <img src={Images.portraitBackgroundVideoPoster} width="100%" height="100%" style={{position: 'fixed'}}/>
    }
    {display?.displayType === 'Node' && tvUid && <div className="over-video">
      <NodePage tvUid={tvUid} portrait={true}/>
    </div>}
    {display?.displayType === 'Device' && tvUid && <div className="over-video">
      <DevicePage tvUid={tvUid} portrait={true}/>
    </div>}
  </> : <>
    <video playsInline autoPlay muted loop id="bgvid" poster={Images.backgroundVideoPoster}>
      <source src={Images.backgroundVideo} type="video/mp4" />
    </video>
    {display?.displayType === 'Node' && tvUid && <div className="over-video">
      <NodePage tvUid={tvUid}/>
    </div>}
    {display?.displayType === 'Device' && tvUid && <div className="over-video">
      <DevicePage tvUid={tvUid}/>
    </div>}
  </>);
};

const DisplayPage: React.FC = (props) => {

  const tvUid = props.match.params.tvUid;
  const {data: display} = useTvClientDisplay(tvUid);

  return (
  isPortraitScreen() ? <>
    {<FullScreen display={display} tvUid={tvUid}/>}
  </> : <>
    {(display?.displayStyle === 'FullScreen' || display?.displayStyle === 'DebugFullScreen') && 
      <FullScreen display={display} tvUid={tvUid}/>
    }
    {(display?.displayStyle === 'Top' || display?.displayStyle === 'DebugTop') && 
      <div style={{width: '100%', top: 0, position: 'fixed'}}>
        <BarDisplayPage display={display} tvUid={tvUid}/>
      </div>
    }
    {(display?.displayStyle === 'Bottom' || display?.displayStyle === 'DebugBottom') && 
      <div style={{width: '100%', bottom: 0, position: 'fixed'}}>
        <BarDisplayPage display={display} tvUid={tvUid}/>
      </div>
    }
  </>);
};


export default DisplayPage;